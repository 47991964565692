import React, {CSSProperties} from "react";
import "./styles.css";
import BeatLoader from "react-spinners/BeatLoader";

interface IButtonProps {
	text: string;
	submit?: boolean;
	disabled?: boolean;
	styleType: "outlined" | "primary" | "text";
	onClick?: () => void;
	style?: CSSProperties;
	loading?: boolean;
}

export default function Button(props:IButtonProps) {

	if (props.styleType === "outlined") {
		return(
			<button
				type={props.submit ? "submit" : "button"}
				disabled={props.disabled || props.loading}
				className="outlined_button"
				onClick={() => props.onClick ? props.onClick() : null}
				style={props.style ? props.style : {}}
			>
				{props.loading
					? <BeatLoader
						size={8}
					/>
					: props.text
				}
			</button>
		)
	} else if (props.styleType === "primary") {
		return (
			<button
				type={props.submit ? "submit" : "button"}
				disabled={props.disabled || props.loading}
				className="primary_button"
				onClick={() => props.onClick ? props.onClick() : null}
				style={props.style ? props.style : {}}
			>
				{props.loading
					? <BeatLoader
						size={8}
						color={"#ffffff"}
					/>
					: props.text
				}
			</button>
		)
	} else if(props.styleType === "text") {
		return (
			<p
				className="text_button"
				onClick={() => props.onClick ? props.onClick() : null}
				style={props.style ? props.style : {}}
			>
				{props.loading
					? <BeatLoader
						size={8}
					/>
					: props.text
				}
			</p>
		)
	} else {
		return(<></>)
	}

}
