import "./KeywordsTab.css";
import Input from "antd/lib/input/Input";
import {Button, Modal, Pagination, Select, Table} from "antd";
import {useEffect, useState} from "react";
import moment from "moment/moment";
import {IGetAllKeywordsParams, IKeywordData} from "../../../../../api/services/keywordsService/keywords.interface";
import KeywordsService from "../../../../../api/services/keywordsService/keywords.service";
import KeywordSeoDescriptionModal
	from "../../../../../components/Modals/KeywordSeoDescriptionModal/KeywordSeoDescriptionModal";
import AddKeywordModal from "../../../../../components/Modals/AddKeywordModal/AddKeywordModal";

export default function KeywordsTab() {

	const [data, setData] = useState<null | {data: any[], total: number}>(null);
	const [pageLimit, setPageLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
	const [currentKeywordData, setCurrentKeywordData] = useState<IKeywordData | null>(null);
	const [showAddKeywordModal, setShowAddKeywordModal] = useState(false);

	useEffect(() => {
		getData({
			limit: pageLimit,
			skip: 0,
			search: search
		});
	}, []);

	const getData = async (params: IGetAllKeywordsParams) => {
		const response = await new KeywordsService().getAll(params);
		setData(response);
	};

	const onChange = (currentPage: number, pageSize: number) => {
		setPage(currentPage);
		setPageLimit(pageSize);
		getData({
			limit: pageSize,
			skip: pageSize * (currentPage - 1),
			search: search
		});
	}

	const onSearch = (e: any) => {
		e.preventDefault();
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search
		})
	}

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: 'Priority',
			dataIndex: 'priority',
			key: 'priority'
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: Date) => <p>
				{moment(createdAt).format("DD.MM.YYYY")}
			</p>
		},
		{
			title: 'Description provided',
			dataIndex: 'description',
			key: 'description',
			render: (description: string | undefined) => <p>
				{description && description.length > 0 ? "Provided" : "Not"}
			</p>
		}
	];

	const onRowClicked = (data: IKeywordData) => {
		setCurrentKeywordData(data);
	}
	return(
		<div>

			<Button
				onClick={() => setShowAddKeywordModal(true)}
			>
				Add keyword
			</Button>

			<Modal
				visible={!!currentKeywordData}
				onCancel={() => setCurrentKeywordData(null)}
				footer={null}
				width={800}
				closable={true}
				maskClosable={true}
			>
				<KeywordSeoDescriptionModal
					data={currentKeywordData}
					close={() => {
						setCurrentKeywordData(null)
					}}
				/>
			</Modal>

			<Modal
				visible={showAddKeywordModal}
				onCancel={() => setShowAddKeywordModal(false)}
				footer={null}
				width={800}
				closable={true}
				maskClosable={true}
			>
				<AddKeywordModal
					onClose={() => {
						getData({
							limit: pageLimit,
							skip: pageLimit * (page - 1),
							search: search
						});
						setShowAddKeywordModal(false)
					}}
				/>
			</Modal>

			{data
				&&
				<>
					<form onSubmit={onSearch}>
						<Input
							placeholder="Search"
							onChange={(e) => setSearch(e.target.value)}
						/>
					</form>
					<Table
						rowKey="_id"
						dataSource={data.data}
						columns={columns}
						pagination={false}
						onRow={(record) => {
							return {
								onClick: () => {
									onRowClicked(record)
								},
							};
						}}
					/>
					<Pagination
						total={data.total}
						showSizeChanger
						onShowSizeChange={onChange}
						onChange={onChange}
					/>
				</>
			}
		</div>
	);
}
