import React from "react";
import "./styles.css";

interface IProps {
	children: React.ReactNode
}

export default function DashboardContentBox(props:IProps) {
	return(
		<div className="dashboard_content">
			{props.children}
		</div>
	)
}
