import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
const queryString = require('query-string');

export interface urlParsedSearchControls {
	create: (params: any) => void;
}

export function useUrlSearchParams() {
	const location = useLocation();
	const [urlSearch, setUrlSearch] = useState<any>({});

	useEffect(() => {
		const parsedSearch = queryString.parse(location.search);
		setUrlSearch(parsedSearch);
	}, [location]);

	const controlUrlSearch: urlParsedSearchControls = {
		create: () => {}
	};

	controlUrlSearch.create = (params: any) => {
		const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${queryString.stringify(params)}`;
		window.history.pushState({path:newUrl},'', newUrl);
	}


	return { controlUrlSearch, urlSearch };
}

