import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers/rootReducer';

const enhancers = [];
const middleware = [thunk];

const persistConfig = {
	key: 'root',
	storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === 'function') {
	enhancers.push(devToolsExtension());
}

const composedEnhancers = compose(
	applyMiddleware(...middleware),
	...enhancers
);

export default () => {
	const store = createStore(persistedReducer, composedEnhancers);
	return { store, persistor: persistStore(store) };
};
