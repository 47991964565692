import React from "react";
import {Route, Routes} from "react-router-dom";
import NotFoundPage from "../../404.page";
import PostsPage from "./posts.page";
import PostDetail from "./detail.page";

export default function PostsRouter() {

	return(
		<Routes>
			<Route path="/" element={<PostsPage />} />
			<Route path="/:id" element={<PostDetail />} />
			<Route path="/*" element={<NotFoundPage />} />
		</Routes>
	)
}
