import React, {useEffect, useState} from "react";
import "./styles.css";
import {Menu, Layout, Modal} from "antd";
import Icon, {KeyOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate} from "react-router-dom";
import logo from "../../img/branding/logo_white.png";
import dashboardIcon from "../../img/components/Sider/dashboard.svg";
import postsIcon from "../../img/components/Sider/posts.svg";
import logoutIcon from "../../img/components/Sider/logout.svg";


const { Sider } = Layout;

interface IProps {
	collapsed: boolean;
	onCollapse: (state: boolean) => void;
	logout: () => void;
}

export default function DashboardSider(props:IProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const [tabFromPath, setTabFromPath] = useState("");

	// Detect path and set selected default tab
	useEffect(() => {
		if (location?.pathname?.split("/").length > 1) {
			setTabFromPath(location?.pathname?.split("/")[1]);
		} else {
			setTabFromPath("");
		}
	}, [location])

	const routes = [
		{
			path: "",
			icon: <Icon component={() => (
				<img
					src={dashboardIcon}
					height={"16px"}
					style={{marginRight: "10px"}}
				/>)} />,
			label: "Dashboard",
			key: ""
		},
		{
			path: "postsForApproval",
			icon: <Icon component={() => (
				<img
					src={postsIcon}
					height={"16px"}
					style={{marginRight: "10px"}}
				/>)} />,
			label: "Posts for approval",
			key: "postsForApproval"
		},
		{
			path: "posts",
			icon: <KeyOutlined />,
			label: "Posts",
			key: "posts"
		},
		{
			path: "keywords",
			icon: <KeyOutlined />,
			label: "Keywords",
			key: "keywords"
		},
		{
			path: "orders",
			icon: <KeyOutlined />,
			label: "Orders",
			key: "orders"
		},
		{
			path: "users",
			icon: <KeyOutlined />,
			label: "Users",
			key: "users"
		},
		{
			path: "contacts",
			icon: <KeyOutlined />,
			label: "Contacts",
			key: "contacts"
		},
		{
			path: "messages",
			icon: <KeyOutlined />,
			label: "Messages",
			key: "messages"
		}
	];

	const bottomMenu = [
		{
			icon: <Icon component={() => (<img
				src={logoutIcon}
				height={"16px"}
				style={{marginRight: "10px"}}
			/>)} />,
			label: "Logout",
			key: "logout"
		}
	]

	const handleBottomMenu = (key:string) => {
		if (key === "homepage") {
			navigate("/")
		} else if (key === "logout") {
			props.logout()
		}
	}

	return(
		<Sider collapsible collapsed={props.collapsed} onCollapse={props.onCollapse}>

			<Link to={""}>
				<div className="logo">
					<img
						src={logo}
						alt="shary_logo"
					/>
				</div>
			</Link>

			<Menu
				theme="dark"
				selectedKeys={[tabFromPath]}
				mode="inline"
				items={routes}
				onClick={(item:any) => navigate(item.item.props.path)}
			/>

			<div
			style={{
				width: "90%",
				marginLeft: "auto",
				marginRight: "auto",
				height: "1px",
				backgroundColor: "#2D3958"
			}}
			/>

			<Menu
				theme="dark"
				selectedKeys={[tabFromPath]}
				mode="inline"
				items={bottomMenu}
				onClick={(item:any) => handleBottomMenu(item.key)}
			/>

		</Sider>
	)
}

