import {IGetAllOrdersParams, IGetAllOrdersResponse} from "./orders.interface";
import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";

export default class OrdersService {
	constructor() {}

	async getAll(params: IGetAllOrdersParams): Promise<IGetAllOrdersResponse> {
		return await requestHandler({
			path: url.dashboard.orders.getAll,
			method: "GET",
			params: params,
			protected: true
		}).then((res: any) => {
			return res.data
		}).catch((e: any) => {
			return {
				total: 0,
				data: []
			}
		});
	}

}
