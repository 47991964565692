import "./OutgoingPaymentsList.css";
import Input from "antd/lib/input/Input";
import {Pagination, Select, Table} from "antd";
import {useEffect, useState} from "react";
import moment from "moment/moment";
import {currencyFormatter} from "../../../../utils/currencyFormatter";
import {
	IGetAllOutgoingPaymentsParams,
	outgoingPaymentsTransferType, outgoingPaymentsValidType
} from "../../../../api/services/outgoingPaymentsService/outgoingPayments.interface";
import OutgoingPaymentsService from "../../../../api/services/outgoingPaymentsService/outgoingPayments.service";

export default function OutgoingPaymentsList() {

	const [data, setData] = useState<null | {data: any[], total: number}>(null);
	const [pageLimit, setPageLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
	const [selectedStatus, setSelectedStatus] = useState<outgoingPaymentsTransferType>("all");
	const [selectedValid, setSelectedValid] = useState<outgoingPaymentsValidType>("");

	useEffect(() => {
		getData({
			limit: pageLimit,
			skip: 0,
			search: search,
			transferType: selectedStatus,
			valid: selectedValid
		});
	}, []);

	const getData = async (params: IGetAllOutgoingPaymentsParams) => {
		const response = await new OutgoingPaymentsService().getAll(params);
		setData(response);
	};

	const onChange = (currentPage: number, pageSize: number) => {
		setPage(currentPage);
		setPageLimit(pageSize);
		getData({
			limit: pageSize,
			skip: pageSize * (currentPage - 1),
			search: search,
			transferType: selectedStatus,
			valid: selectedValid
		});
	}

	const columns = [
		{
			title: 'Valid',
			dataIndex: 'valid',
			key: 'valid',
			render: (valid: boolean) => <p>
				{valid ? "Valid" : "Not valid"}
			</p>
		},
		{
			title: 'Receiver full name',
			dataIndex: 'receiverFullName',
			key: 'receiverFullName',
		},
		{
			title: 'Receiver IBAN',
			dataIndex: 'receiverIban',
			key: 'receiverIban',
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (amount: number) => <p>
				{currencyFormatter({
					amount: amount,
					currency: "EUR"
				})}
			</p>
		},
		{
			title: 'Transfer type',
			dataIndex: 'transferType',
			key: 'transferType',
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: Date) => <p>
				{moment(createdAt).format("DD.MM.YYYY")}
			</p>
		}
	];

	const handleSelectChange = (value: outgoingPaymentsTransferType) => {
		setSelectedStatus(value);
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search,
			transferType: value,
			valid: selectedValid
		})
	}

	const handleValidSelectChange = (value: outgoingPaymentsValidType) => {
		setSelectedValid(value);
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search,
			transferType: selectedStatus,
			valid: value
		})
	}

	const onSearch = (e: any) => {
		e.preventDefault();
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search,
			transferType: selectedStatus,
			valid: selectedValid
		})
	}

	return(
		<div>
			{data
				&&
				<>
					<form onSubmit={onSearch}>
						<Input
							placeholder="Search"
							onChange={(e) => setSearch(e.target.value)}
						/>
					</form>
					<div style={{display: "flex", alignItems: "center"}}>
						<div>
							<p style={{marginBottom: 0}}>Status</p>
							<Select
								defaultValue="all"
								style={{ width: 120 }}
								onChange={handleSelectChange}
								options={[
									{ value: "all", label: "All" },
									{ value: "internal", label: "Internal" },
									{ value: "customer", label: "Customer" }
								]}
							/>
						</div>
						<div>
							<p style={{marginBottom: 0}}>Valid</p>
							<Select
								defaultValue=""
								style={{ width: 120 }}
								onChange={handleValidSelectChange}
								options={[
									{ value: "", label: "All" },
									{ value: "true", label: "Valid" },
									{ value: "false", label: "Not Valid" }
								]}
							/>
						</div>
					</div>
					<Table
						rowKey="_id"
						dataSource={data.data}
						columns={columns}
						pagination={false}
					/>
					<Pagination
						total={data.total}
						showSizeChanger
						onShowSizeChange={onChange}
						onChange={onChange}
					/>
				</>
			}
		</div>
	);
}
