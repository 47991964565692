import React, {useEffect, useState} from "react";
import "./styles.css";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import FormikInput from "../../components/Inputs/FormikInput";
import Button from "../../components/Button/Button";
import {requestHandler} from "../../utils/requestHandler";
import {url} from "../../api/urls";
import {notification} from "antd";
import {setToken} from "../../utils/cookie/token.cookie";
import {Link, useNavigate} from "react-router-dom";
import {useCustomNavigation} from "../../hooks/useCustomNavigation";
import {useDispatch} from "react-redux";
import {setUser} from "../../redux/actions/userActions";
import i18next from "i18next";

interface IProps {
	responseGoogle: (res:any, termsNotAccepted:boolean) => void;
}

export default function SigninPage(props:IProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const customNavigate = useCustomNavigation();
	const dispatch = useDispatch();

	const [showNotActivatedWindow, setShowNotActivatedWindow] = useState(false);
	const [showNotActiveWindow, setShowNotActiveWindow] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false)

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("email not valid")
				.required("Emaiul is required"),
			password: Yup.string().required("Password is required")
				.min(8, "Should be at least 8 characters")
		}),
		// handle form submitting
		onSubmit: () => {
			requestHandler({
				path: url.auth.signin,
				method: "POST",
				data: formik.values
			}).then((res:any) => {
				if (res && res.status === 201) {
					if (res.data && res.data.token) {
						setToken(res.data.token);
						dispatch(setUser())
						customNavigate(navigate, "dashboard");
					}
				} else {
					if (res.data === "not_active") {
						setShowNotActiveWindow(true)
					} else if (res.data === "not_activated") {
						setShowNotActivatedWindow(true);
					} else if (res.data === "auth_error") {
						notification.error({
							message: "Wrong credentials"
						})
					}
				}
			}).catch((e) => {
				notification.error({
					message: "Something went wrong"
				});
			}).finally(() => {
				setButtonLoading(false);
			});
		},
	});

	return(
		<form
			onSubmit={formik.handleSubmit}
			className="auth"
		>

			<h1>Signin</h1>

			{/*Email*/}
			<FormikInput
				htmlFor={"email"}
				name={"email"}
				value={formik.values.email}
				handleChange={formik.handleChange}
				placeholder={"Email"}
				error={formik.errors.email}
				onBlur={formik.handleBlur}
				touched={formik.touched.email}
				style={{maxWidth: "100%"}}
			/>

			<div style={{height: "20px"}} />

			{/*Password*/}
			<FormikInput
				htmlFor={"password"}
				name={"password"}
				value={formik.values.password}
				handleChange={formik.handleChange}
				placeholder={"Password"}
				error={formik.errors.password}
				onBlur={formik.handleBlur}
				touched={formik.touched.password}
				style={{maxWidth: "100%"}}
			/>

			<div style={{height: "20px"}} />

			<Button
				styleType={"primary"}
				text={"Signin"}
				submit={true}
				disabled={!(formik.isValid && formik.dirty)}
				loading={buttonLoading}
				style={{width: "100%"}}
			/>
		</form>
	)
}
