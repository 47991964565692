import {useTranslation} from "react-i18next";
import {Tabs} from "antd";
import React from "react";
import OrdersList from "../OrdersList/OrdersList";
import CheckoutsList from "../CheckoutsList/CheckoutList";
import PaymentsList from "../PaymentsList/PaymentsList";
import OutgoingPaymentsList from "../OutgoingPaymentsList/OutgoingPaymentsList";
import OrdersDashboard from "../Dashboard/Dashboard";

const { TabPane } = Tabs;

export default function AllOrdersPage() {
	const { t } = useTranslation();

	return(
		<div
			className="page"
		>
			<h1>Orders</h1>

			<Tabs>
				<TabPane tab="Dashboard" key="dashboard">
					<OrdersDashboard />
				</TabPane>
				<TabPane tab="Orders List" key="ordersList">
					<OrdersList />
				</TabPane>
				<TabPane tab="Checkouts List" key="checkoutsList">
					<CheckoutsList />
				</TabPane>
				<TabPane tab="Payments List" key="paymentsList">
					<PaymentsList />
				</TabPane>
				<TabPane tab="Outgoing payments List" key="outgoingPaymentsList">
					<OutgoingPaymentsList />
				</TabPane>

			</Tabs>

		</div>
	)
}
