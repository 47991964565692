import {Route, Routes} from "react-router-dom";
import React from "react";
import ContactsPage from "./ContactsPage/ContactsPage";

export default function ContactsRouter() {
	return(
		<Routes>
			<Route path="/" element={<ContactsPage />} />
		</Routes>
	)
}
