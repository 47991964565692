import "./AllSearchKeysTabs.css";
import {useEffect, useState} from "react";
import {Table} from "antd";
import moment from "moment";
import { Pagination } from 'antd';
import Input from "antd/lib/input/Input";
import SearchKeysService from "../../../../../../api/services/searchKeysService/searchKeys.service";
import {IGetAllSearchKeysRequestParams} from "../../../../../../api/services/searchKeysService/searchKeys.interface";

export default function AllSearchKeysTabs() {

	const [data, setData] = useState<null | {data: any[], total: number}>(null);
	const [pageLimit, setPageLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");

	useEffect(() => {
		getData({
			limit: pageLimit,
			skip: 0
		});
	}, []);

	const getData = async (params: IGetAllSearchKeysRequestParams) => {
		const response = await new SearchKeysService().getAll(params);
		setData(response.data);
	};

	const onChange = (currentPage: number, pageSize: number) => {
		setPage(currentPage);
		setPageLimit(pageSize);
		getData({
			limit: pageSize,
			skip: pageSize * (currentPage - 1),
			search: search
		});
	}

	const deleteItem = async (itemId: string) => {
		await new SearchKeysService().delete(itemId).then(() => {
			getData({
				limit: pageLimit,
				skip: pageLimit * (page - 1),
				search: search
			})
		});
	}

	const onSearch = (e: any) => {
		e.preventDefault();
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search
		})
	}

	const columns = [
		{
			title: 'Key',
			dataIndex: 'key',
			key: 'key',
		},
		{
			title: 'Requests Counter',
			dataIndex: 'counter',
			key: 'counter',
		},
		{
			title: 'Created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (date: Date) => <p>{moment(date).format("DD.MM.YYYY")}</p>
		},
		{
			title: 'Delete',
			dataIndex: '_id',
			key: '_id',
			render: (itemId: any) => <p
				style={{cursor: "pointer"}}
				onClick={() => deleteItem(itemId)}
			>
				Delete from list
			</p>
		},
	];

	return(
		<div>
			{data
				&&
				<>
					<form onSubmit={onSearch}>
						<Input
							placeholder="Search"
							onChange={(e) => setSearch(e.target.value)}
						/>
					</form>
					<Table
						dataSource={data.data}
						columns={columns}
						pagination={false}
					/>
					<Pagination
						total={data.total}
						showSizeChanger
						onShowSizeChange={onChange}
						onChange={onChange}
					/>
				</>
			}
		</div>
	)
}
