import React, {useEffect, useState} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import NotFoundPage from "../404.page";
import DashboardPage from "./dashboard/dashboard.page";
import PostsRouter from "./posts/posts.router";
import {Layout} from "antd";
import DashboardSider from "../../components/Sider/Sider";
import {useDispatch} from "react-redux";
import "./styles.css";
import {useWindowSize} from "../../hooks/useWindowSize";
import {mobileScreenWidth} from "../../constants";
import MobileSider from "../../components/MobileSider/MobileSider";
import {removeToken} from "../../utils/cookie/token.cookie";
import {requestHandler} from "../../utils/requestHandler";
import {url} from "../../api/urls";
import {SET_USER_INFO} from "../../redux/types/userTypes";
import KeywordsRouter from "./keywords/keywords.router";
import OrdersRouter from "./orders/orders.router";
import UsersRouter from "./users/users.router";
import ContactsRouter from "./contacts/contacts.router";
import MessagesRouter from "./messages/messages.router";
import AllPostsRouter from "./allPosts/allPosts.router";

const { Content } = Layout;

export default function DashboardRouter() {
	const dispatch = useDispatch();
	const { width } = useWindowSize()
	const navigate = useNavigate();

	const getUserInfo = async () => {
		await requestHandler({
			path: url.dashboard.user.getAdminByToken,
			method: "GET",
			protected: true
		}).then((res: any) => {
			if (res && res.status === 200) {
				dispatch({
					type: SET_USER_INFO,
					payload: res.data
				})
			}
		}).catch((err:any) => {
			logout()
		})
	}

	useEffect(() => {
		getUserInfo();
	}, [])

	const [collapsed, setCollapsed] = useState(false)


	const onCollapse = (collapsed:boolean) => {
		setCollapsed(collapsed)
	};

	const logout = async () => {
		// Clear redux persist state
		localStorage.clear();
		// Remove token from storage
		await removeToken();
		setTimeout(() => {
			navigate("auth/signin")
		}, 100)
	}

	return(

		<Layout style={{ minHeight: '100vh' }}>

			{width && width > mobileScreenWidth
				? <DashboardSider
					collapsed={collapsed}
					onCollapse={onCollapse}
					logout={logout}
				/>
				: <MobileSider
					logout={logout}
				/>
			}

			<Layout className="site-layout">
				<Content>
					<Routes>
						<Route path="/" element={<DashboardPage />} />
						<Route path="/postsForApproval/*" element={<PostsRouter />} />
						<Route path="/posts/*" element={<AllPostsRouter />} />
						<Route path="/keywords/*" element={<KeywordsRouter />} />
						<Route path="/orders/*" element={<OrdersRouter />} />
						<Route path="/users/*" element={<UsersRouter />} />
						<Route path="/contacts/*" element={<ContactsRouter />} />
						<Route path="/messages/*" element={<MessagesRouter />} />
						<Route path="/*" element={<NotFoundPage />} />
					</Routes>
				</Content>
			</Layout>

		</Layout>
	)
}
