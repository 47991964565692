import {useTranslation} from "react-i18next";
import {Tabs} from "antd";
import React from "react";
import UsersList from "./UsersList/UsersList";

const { TabPane } = Tabs;

export default function UsersPage() {
	const { t } = useTranslation();

	return(
		<div
			className="page"
		>
			<h1>Users</h1>

			<Tabs>

				<TabPane tab="Dashboard" key="dashboard">
					{/*<AllOrdersPage />*/}
				</TabPane>

				<TabPane tab="Users list" key="usersList">
					<UsersList />
				</TabPane>

			</Tabs>

		</div>
	)
}
