import React from "react";
import "./styles.css";
import {useTranslation} from "react-i18next";
import errorImage from "../img/home/500.png";
import reloadIcon from "../img/home/reload.png";
import {Link} from "react-router-dom";

export default function ServerErrorPage() {
	const { t } = useTranslation()

	return(
		<div className="error_page">
			<img src={errorImage} alt="500_shary"/>
			<h1>500 Error</h1>
			<Link to={"/"}>
				<div className="error_page_reload_btn">
					<img src={reloadIcon} alt="reload_shary"/>
					<p>Reload</p>
				</div>
			</Link>
		</div>
	)
}
