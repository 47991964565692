import React, {useState} from "react";
import { DatePicker } from 'antd';
import moment from "moment";

const { RangePicker } = DatePicker;

export default function OrdersDashboard() {
	const [checkoutsData, setCheckoutsData] = useState(null);

	const getData = (dates: {startDate: Date, endDate: Date}) => {
		console.log(dates)
	}

	return(
		<div>
			<RangePicker
				onChange={(values) => {
					if (values) {
						getData({
							startDate: moment(values[0]).startOf("day").toDate(),
							endDate: moment(values[1]).endOf("day").toDate(),
						});
					}
				}}
			/>
		</div>
	);
}
