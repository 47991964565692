import findValueByKeyPath from "./findValueInObjectByKeyPath";

export interface IDataToValueTransformerParams {
	label: string;
	value: string;
	data: any[];
}

export default function dataToValueTransformer(data: IDataToValueTransformerParams) {
	if (data.data.length > 0) {
		const transformedData = data.data.map((dataItem: any) => {
			const label = findValueByKeyPath(dataItem, data.label);
			const value = findValueByKeyPath(dataItem, data.value);
			return {
				label,
				value
			}
		})
		return transformedData;
	} else {
		return [];
	}
}

