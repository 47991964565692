export const url = {
	"auth": {
		"signin": "customer/auth/signin"
	},
	"dashboard": {
		"user": {
			"getAdminByToken": "customer/user/getAdminByToken",
			"getAll": "customer/user/getAll"
		},
		"posts": {
			"getPostsForApproval": "customer/post/getPostsForApproval",
			"getAll": "customer/post/getAll",
			"getOnePostForApproval": "customer/post/getOnePostForApproval",
			"approve": "customer/post/approve",
			"reject": "customer/post/reject",
			"switchInsurance": "customer/post/switchInsurance",
			"update": "customer/post"
		},
		"categories": {
			"getAll": "customer/category",
			"getAllCategoriesAndSubCategories": "customer/category/getAllCategoriesAndSubCategories",
			"updateCategoriesKeywords": "customer/category/updateCategoriesKeywords"
		},
		"subCategories": {
			"getByParentPath": "customer/subCategory/parentPath"
		},
		"searchKeys": {
			"getAll": "customer/searchRequest",
			"delete": "customer/searchRequest"
		},
		"orders": {
			"getAll": "customer/order/getAll"
		},
		"checkouts": {
			"getAll": "customer/checkout/getAll",
			"getStatistic": "customer/checkout/getStatistic"
		},
		"contacts": {
			"getAll": "customer/contact",
			"changeStatus": "customer/contact/changeStatus",
			"answer": "customer/contact/answer"
		},
		"reports": {
			"getAll": "customer/articleReport",
			"changeStatus": "customer/articleReport/changeStatus",
			"answer": "customer/articleReport/answer"
		},
		"payments": {
			"getAll": "customer/payment"
		},
		"outgoingPayments": {
			"getAll": "customer/outgoingPayment/getAll"
		},
		"keywords": {
			"getAll": "customer/seoKeyword/getAll",
			"addDescription": "customer/seoKeyword/addDescription",
			"create": "customer/seoKeyword"
		},
		"messages": {
			"getAll": "customer/message"
		}
	},
	"general": {
		"file_read": "customer/files/read"
	}
}
