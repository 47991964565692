import "./CheckoutList.css";
import Input from "antd/lib/input/Input";
import {Pagination, Select, Table} from "antd";
import {useEffect, useState} from "react";
import moment from "moment/moment";
import {currencyFormatter} from "../../../../utils/currencyFormatter";
import {checkoutStatusType, getAllCheckoutsQuery} from "../../../../api/services/CheckoutsService/checkout.interface";
import CheckoutService from "../../../../api/services/CheckoutsService/checkout.service";

export default function CheckoutsList() {

	const [data, setData] = useState<null | {data: any[], total: number}>(null);
	const [pageLimit, setPageLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
	const [selectedStatus, setSelectedStatus] = useState<checkoutStatusType>("all");

	useEffect(() => {
		getData({
			limit: pageLimit,
			skip: 0,
			status: selectedStatus
		});
	}, []);

	const getData = async (params: getAllCheckoutsQuery) => {
		const response = await new CheckoutService().getAll(params);
		setData(response);
	};

	const onChange = (currentPage: number, pageSize: number) => {
		setPage(currentPage);
		setPageLimit(pageSize);
		getData({
			limit: pageSize,
			skip: pageSize * (currentPage - 1),
			search: search,
			status: selectedStatus
		});
	}

	const onSearch = (e: any) => {
		e.preventDefault();
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search,
			status: selectedStatus
		})
	}

	const columns = [
		{
			title: 'Post Title',
			dataIndex: 'title',
			key: 'title',
			render: (title: string) => <p style={{margin: 0}}>
				{title.length > 20 ? title.slice(0, 20).concat('...') : title}
			</p>
		},
		{
			title: 'Customer',
			dataIndex: 'customer',
			key: 'customer',
			render: (customer: any) => <p style={{margin: 0}}>
				{customer[0].email}
			</p>
		},
		{
			title: 'Owner',
			dataIndex: 'owner',
			key: 'owner',
			render: (owner: any) => <p style={{margin: 0}}>
				{owner && owner[0]?.email || ""}
			</p>
		},
		{
			title: 'Paid amount',
			dataIndex: 'totalToPay',
			key: 'totalToPay',
			render: (totalToPay: number) => <p style={{margin: 0}}>
				{currencyFormatter({
					currency: "EUR",
					amount: totalToPay
				})}
			</p>
		},
		{
			title: 'Start Date',
			dataIndex: 'startDate',
			key: 'startDate',
			render: (startDate: Date) => <p style={{margin: 0}}>
				{moment(startDate).format("DD.MM.YYYY")}
			</p>
		},
		{
			title: 'End Date',
			dataIndex: 'endDate',
			key: 'endDate',
			render: (endDate: Date) => <p style={{margin: 0}}>
				{moment(endDate).format("DD.MM.YYYY")}
			</p>
		},
		{
			title: 'Rental duration',
			dataIndex: 'rentalDuration',
			key: 'rentalDuration',
			render: (rentalDuration: number) => <p style={{margin: 0}}>
				{`${rentalDuration || 0} days`}
			</p>
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status'
		},
		{
			title: 'Device',
			dataIndex: 'device',
			key: 'device'
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: number) => <p style={{margin: 0}}>
				{moment(createdAt).format("DD.MM.YYYY")}
			</p>
		}
	];

	const handleSelectChange = (value: checkoutStatusType) => {
		setSelectedStatus(value);
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search,
			status: value
		})
	}

	return(
		<div>
			{data
				&&
				<>
					<Select
						defaultValue="all"
						style={{ width: 120 }}
						onChange={handleSelectChange}
						options={[
							{ value: "all", label: "All" },
							{ value: "created", label: "Created" },
							{ value: "completed", label: "Completed" }
						]}
					/>
					<form onSubmit={onSearch}>
						<Input
							placeholder="Search"
							onChange={(e) => setSearch(e.target.value)}
						/>
					</form>
					<Table
						rowKey="_id"
						dataSource={data.data}
						columns={columns}
						pagination={false}
					/>
					<Pagination
						total={data.total}
						showSizeChanger
						onShowSizeChange={onChange}
						onChange={onChange}
					/>
				</>
			}
		</div>
	);
}
