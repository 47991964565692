import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";

const cookies = new Cookies();

export function decryptWithAES(ciphertext: any) {
  const passphrase = "shary";
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}

export function encryptWithAES(text: string) {
  const passphrase = "shary";
  return CryptoJS.AES.encrypt(text, passphrase).toString();
}

export function setToken(token: string) {
  const encryptedRole = encryptWithAES(token.toString());
  cookies.set("key", encryptedRole, { path: "/" });
}


export async function getToken() {
	const token = await cookies.get("key");
	if (token) {
		return decryptWithAES(token);
	}
}

export async function removeToken() {
	await cookies.remove("key", {path: "/"});
	return true
}

