import React, {useEffect, useState} from "react";
import "./styles.css";
import { scaleDown as Menu } from 'react-burger-menu'
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Button from "../Button/Button";
import logo from "../../img/branding/logo_white.png";
import dashboardIcon from "../../img/components/Sider/dashboard.svg";
import profileIcon from "../../img/components/Sider/profile.svg";
import postsIcon from "../../img/components/Sider/posts.svg";
import wishlistIcon from "../../img/components/Sider/wishlist.svg";
import chatIcon from "../../img/components/Sider/chat.svg";
import ratingsIcon from "../../img/components/Sider/ratings.svg";
import rentedIcon from "../../img/components/Sider/rented.svg";
import borrowedIcon from "../../img/components/Sider/borrowed.svg";
import languageIcon from "../../img/components/Sider/language.svg";
import homeIcon from "../../img/components/Sider/home.svg";

interface IProps {
	logout: () => void;
}

export default function MobileSider(props:IProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const [siderOpen, setSiderOpen] = useState(false)
	const [tabFromPath, setTabFromPath] = useState("");

	// Detect path and set selected default tab
	useEffect(() => {
		if (location?.pathname?.split("/").length > 3) {
			setTabFromPath(location?.pathname?.split("/")[3]);
		} else {
			setTabFromPath("");
		}
	}, [location])

	const tabs = [
		{
			route: "",
			icon: dashboardIcon,
			alt: "dashboard_shary",
			text: "Dashboard"
		}
	]

	return(
		<Menu
			isOpen={siderOpen}
			onOpen={() => setSiderOpen(true)}
			onClose={() => setSiderOpen(false)}
			pageWrapId={"mobile_dashboard_page"}
		>
			<div
				onClick={() => setSiderOpen(false)}
				className="mobile_sider_wrapper"
			>
				<div style={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginBottom: "20px"
				}}>
					<Link to={""}>
						<img
							src={logo}
							alt="shary_logo"
							className="mobile_sider_logo"
						/>
					</Link>
				</div>

				{tabs.map((tabItem:any) => (
					<Link
						to={tabItem.route}
						key={tabItem.text}
					>
						<img
							className={tabFromPath === tabItem.route ? "selected" : ""}
							src={tabItem.icon}
							alt={tabItem.alt}
						/>
						<p className={tabFromPath === tabItem.route ? "selected" : ""}>
							{tabItem.text}
						</p>
					</Link>
				))}

				<div className="mobile_sider_line" />
				<Link to={"/"}>
					<img src={homeIcon} alt="home_shary"/>
					<p>{"home"}</p>
				</Link>

				<div>
					<img src={languageIcon} alt="logout_shary"/>
					<p
						onClick={() => props.logout()}
					>
						{"Logout"}
					</p>
				</div>

				<Button
					text={"Upload Item"}
					styleType={"outlined"}
					onClick={() => navigate("posts/upload")}
					style={{
						color: "#ffffff",
						borderColor: "#ffffff",
						marginTop: "20px"
					}}
				/>
			</div>

		</Menu>
	)
}
