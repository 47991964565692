import React from "react";
import {Route, Routes} from "react-router-dom";
import MainKeywordsPage from "./CategoryKeywordsPage/MainKeywordsPage";

export default function KeywordsRouter() {

	return(
		<Routes>
			<Route path="/" element={<MainKeywordsPage />} />
		</Routes>
	)
}
