import React, {useEffect, useState} from "react";
import {Pagination, Table} from "antd";
import {IGetAllOrdersParams} from "../../../api/services/ordersService/orders.interface";
import {currencyFormatter} from "../../../utils/currencyFormatter";
import moment from "moment";
import MessagesService from "../../../api/services/messagesService/messages.service";
import {IMessage} from "../../../api/services/messagesService/messages.interface";

export default function MessagesRouter() {
	const [data, setData] = useState<null | {data: IMessage[], total: number}>(null);
	const [pageLimit, setPageLimit] = useState(100);
	const [page, setPage] = useState(1);

	useEffect(() => {
		getData({
			limit: pageLimit,
			skip: 0
		});
	}, []);

	const getData = async (params: IGetAllOrdersParams) => {
		const response = await new MessagesService().getAll(params);
		if (response.status && response.data) {
			setData(response.data);
		}
	};

	const onChange = (currentPage: number, pageSize: number) => {
		setPage(currentPage);
		setPageLimit(pageSize);
		getData({
			limit: pageSize,
			skip: pageSize * (currentPage - 1)
		});
	}

	const columns = [
		{
			title: 'Message',
			dataIndex: 'text',
			key: 'text'
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (date: Date) => <p>
				{moment(date).format("DD.MM.YYYY HH:mm")}
			</p>
		},
		{
			title: 'senderId',
			dataIndex: 'senderId',
			key: 'senderId'
		}
	];

	return(
		<div>
			{data
				&&
				<>
					<Table
						rowKey="_id"
						dataSource={data.data}
						columns={columns}
						pagination={false}
					/>
					<Pagination
						total={data.total}
						showSizeChanger
						onShowSizeChange={onChange}
						onChange={onChange}
						defaultPageSize={pageLimit}
					/>
				</>
			}
		</div>
	)
}
