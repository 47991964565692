import {Route, Routes} from "react-router-dom";
import React from "react";
import UsersPage from "./UsersPage/UsersPage";

export default function UsersRouter() {
	return(
		<Routes>
			<Route path="/" element={<UsersPage />} />
		</Routes>
	)
}
