import React from "react";
import "./styles.css";
import errorImage from "img/home/404.png";
import Button from "../components/Button/Button";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function NotFoundPage() {
	const { t } = useTranslation()

	return(
		<div className="error_page">
			<img src={errorImage} alt="404_shary"/>
			<Link to={"/"}>
				<Button
					text={"Home page"}
					styleType={"primary"}
				/>
			</Link>
		</div>
	)
}
