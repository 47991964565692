import React from "react";
import {Route, Routes} from "react-router-dom";
import AllPostsPage from "./allPosts.page";

export default function AllPostsRouter() {

	return(
		<Routes>
			<Route path="/" element={<AllPostsPage />} />
		</Routes>
	)
}
