import ImageGallery from 'react-image-gallery';
import "./ImageGallery.css";
import {Skeleton} from "antd";
import arrowImage from "../../img/home/posts/arrow.png";
import ImageGalleryImage from "./ImageGalleryImage/ImageGalleryImage";
import ImageGalleryThumbnail from "./ImageGalleryThumbnail/ImageGalleryThumbnail";

interface IProps {
	loading: boolean;
	imagesUrls: any[];
	editable?: boolean;
}

export default function ImageGalleryComponent(props:IProps) {

	return(
		<div className="image_gallery_container">
			{props.loading
				? <Skeleton.Image
					style={{
						width: "500px",
						height: "300px"
					}}
				/>
				: <ImageGallery
					items={props.imagesUrls}
					renderPlayPauseButton={() => <></>}
					renderFullscreenButton={() => <></>}
					renderThumbInner={(image:any) => <ImageGalleryThumbnail imageUrl={image.thumbnail} />}
					renderItem={(imageOgj:any) => <ImageGalleryImage
						imageUrl={imageOgj}
						editable={props.editable}
					/>}
					thumbnailPosition={"bottom"}
					renderLeftNav={(slide:any) => {
						return <div className="image_gallery_arrow_container">
							<img
								onClick={() => slide()}
								src={arrowImage}
								alt="leftArrow"
								className="image_gallery_arrow_left"
							/>
						</div>
					}}
					renderRightNav={(slide:any) => {
						return <div className="image_gallery_arrow_container">
							<img
								onClick={() => slide()}
								src={arrowImage}
								alt="leftArrow"
								className="image_gallery_arrow_right"
							/>
						</div>
					}}
				/>
			}
		</div>
	)
}
