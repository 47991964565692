import {
	getPostsForApprovalType,
	IGetAllPosts,
	IGetAllPostsResponse,
	ISwitchPostInsuranceParams,
	ISwitchPostInsuranceResponse,
	rejectPostApiType,
	updatePostDataResponseType,
	updatePostDataType
} from "./posts.service.interface";
import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";

export default class PostsService {
	constructor() {}

	getPostsForApproval(query: getPostsForApprovalType) {
		return requestHandler({
			path: url.dashboard.posts.getPostsForApproval,
			method: "GET",
			protected: true,
			params: query
		}).then((res:any) => {
			return {
				status: true,
				data: res.data
			}
		}).catch((e: any) => {
			return {
				status: false,
				data: e.response
			}
		})
	}

	getOnePostForApproval(id: string) {
		return requestHandler({
			path: `${url.dashboard.posts.getOnePostForApproval}/${id}`,
			method: "GET",
			protected: true
		}).then((res:any) => {
			return {
				status: true,
				data: res.data
			}
		}).catch((e: any) => {
			return {
				status: false,
				data: e.response
			}
		})
	}

	getAllPosts(params: IGetAllPosts): Promise<IGetAllPostsResponse> {
		return requestHandler({
			path: url.dashboard.posts.getAll,
			method: "GET",
			protected: true,
			params: params
		})
			.then((res: any) => {
				return {
					status: true,
					data: res.data
				}
			})
			.catch((e: any) => {
				console.error(e);
				return {
					status: false
				}
			});
	}

	approve(id: string) {
		return requestHandler({
			path: `${url.dashboard.posts.approve}`,
			method: "POST",
			protected: true,
			data: {
				postId: id
			}
		}).then((res:any) => {
			return {
				status: true,
				data: res.data
			}
		}).catch((e: any) => {
			return {
				status: false,
				data: e.response
			}
		})
	}

	reject(data: rejectPostApiType) {
		return requestHandler({
			path: `${url.dashboard.posts.reject}`,
			method: "POST",
			protected: true,
			data: {
				postId: data.postId,
				reason: data.reason,
				comment: data.comment
			}
		}).then((res:any) => {
			return {
				status: true,
				data: res.data
			}
		}).catch((e: any) => {
			return {
				status: false,
				data: e.response
			}
		})
	}

	updatePost(path: string, data: updatePostDataType): Promise<updatePostDataResponseType> {
		return requestHandler({
			path: `${url.dashboard.posts.update}/${path}`,
			method: "PUT",
			protected: true,
			data: data
		}).then((res:any) => {
			return {
				status: true,
				data: res.data
			}
		}).catch((e: any) => {
			return {
				status: false,
				data: e.response
			}
		})
	}

	switchInsurance(data: ISwitchPostInsuranceParams): Promise<ISwitchPostInsuranceResponse> {
		return requestHandler({
			path: `${url.dashboard.posts.switchInsurance}`,
			method: "PUT",
			protected: true,
			data: data
		}).then((res:any) => {
			return {
				status: true
			}
		}).catch((e: any) => {
			return {
				status: false
			}
		})
	}

}
