import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";
import {
	IAddDescriptionToKeyword,
	IAddDescriptionToKeywordResponse, ICreateKeyword, ICreateKeywordResponse,
	IGetAllKeywordsParams,
	IGetAllKeywordsResponse
} from "./keywords.interface";

export default class KeywordsService {
	constructor() {}

	async getAll(params: IGetAllKeywordsParams): Promise<IGetAllKeywordsResponse> {
		return await requestHandler({
			path: url.dashboard.keywords.getAll,
			method: "GET",
			params: params,
			protected: true
		}).then((res: any) => {
			return res.data
		}).catch((e: any) => {
			return {
				total: 0,
				data: []
			}
		});
	}

	async addDescriptionToKeyword(data: IAddDescriptionToKeyword): Promise<IAddDescriptionToKeywordResponse> {
		return await requestHandler({
			path: url.dashboard.keywords.addDescription,
			method: "PUT",
			data: data,
			protected: true
		}).then(() => {
			return {
				status: true
			};
		}).catch((e: any) => {
			return {
				status: false
			};
		});
	}

	async create(data: ICreateKeyword): Promise<ICreateKeywordResponse> {
		return await requestHandler({
			path: url.dashboard.keywords.create,
			method: "POST",
			data: data,
			protected: true
		}).then((res: any) => {
			return {
				status: true,
				message: res.data
			}
		}).catch((e: any) => {
			return {
				status: false,
				message: "error"
			}
		});
	}

}
