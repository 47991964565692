import React, {useEffect} from "react";
import {Link, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import SigninPage from "./signin.page";
import NotFoundPage from "../404.page";
import {getToken} from "../../utils/cookie/token.cookie";
import {useCustomNavigation} from "../../hooks/useCustomNavigation";
import "./styles.css";
import logo from "img/branding/logo_white.png";
import logoDark from "img/branding/logo_dark.png";
import {mobileScreenWidth} from "../../constants";
import {useWindowSize} from "../../hooks/useWindowSize";

export default function AuthRouter() {
	const navigate = useNavigate();
	const customNavigate = useCustomNavigation();
	const { width } = useWindowSize()

	const checkAuth = async () => {
		const token = await getToken();
		if (token && token.length) {
			customNavigate(navigate, "dashboard")
		}
	}

	useEffect(() => {
		checkAuth();
	}, [])

	return(
		<div className="auth_page">
			{width && width > mobileScreenWidth
				? null
				: <img src={logoDark} className="auth_page_left_logo" alt="shary"/>
			}
			<div
				className="auth_page_left"
			>
				<Link to={"/"}>
					<img src={logo} className="auth_page_left_logo" alt="shary"/>
				</Link>
			</div>
			<div className="auth_page_right">
				<Routes>
					<Route path="/" element={<Navigate to={"signin"} />} />
					<Route
						path="signin"
						element={
							<SigninPage
								responseGoogle={() => console.error("OK")}
							/>
						}
					/>
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
			</div>

		</div>
	)
}
