import "./SearchKeysTab.css";
import React from "react";
import AllSearchKeysTabs from "./AllSearchKeysTabs/AllSearchKeysTabs";

export default function SearchKeysTab() {
	return(
		<div>
			<h1>Search Keys</h1>
			<AllSearchKeysTabs />
		</div>
	);
}
