import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";
import {IGetAllUsersParams, IGetAllUsersResponse} from "./users.interface";

export default class UsersService {
	constructor() {}

	async getAll(params: IGetAllUsersParams): Promise<IGetAllUsersResponse>{
		return await requestHandler({
			path: url.dashboard.user.getAll,
			method: "GET",
			params: params,
			protected: true
		}).then((res: any) => {
			return res.data
		}).catch((e: any) => {
			return {
				total: 0,
				data: []
			}
		});
	}

}
