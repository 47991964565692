export default function findValueByKeyPath(obj: {[key: string]: any}, path: string): any {
	const parts = path.split('.');
	let currentValue = obj;
	for (const part of parts) {
		currentValue = currentValue[part];
		if (currentValue === undefined) {
			return undefined;
		}
	}
	return currentValue;
}
