import "./App.css";
import React, {useLayoutEffect, useState} from "react";
import { connect } from "react-redux";
import {Routes, Route, Router} from "react-router-dom";
import "antd/dist/antd.css";
import customHistory from "./customHistory";
import AuthRouter from "./pages/auth/auth.router";
import DashboardRouter from "./pages/dashboard/dashboard.router";
import NotFoundPage from "./pages/404.page";
import ServerErrorPage from "./pages/500.page";

function App() {

  const CustomRouter = ({ history, ...props }:any) => {
    const [state, setState] = useState({
      action: history.action,
      location: history.location
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
      <Router
        {...props}
        location={state.location}
        navigationType={state.action}
        navigator={history}
      />
    );
  };

  return (
    <div>
		<CustomRouter history={customHistory}>
			<Routes>
				<Route path="/auth/*" element={<AuthRouter />} />
				<Route path="/*" element={<DashboardRouter />} />
				<Route path="/error" element={<NotFoundPage />} />
				<Route path="/server-error" element={<ServerErrorPage />} />
			</Routes>
		</CustomRouter>
    </div>
  );
}

export default connect(null, null)(App);
