import React, {useState} from "react";
import "./styles.css";
import eyeOpen from "../../img/components/Inputs/eye_open.png";
import eyeClosed from "../../img/components/Inputs/eye_closed.png";
import { InputNumber } from 'antd';

interface CustomInputProps {
	htmlFor: string;
	name: string;
	placeholder: string;
	value: string | number | undefined;
	disabled?: boolean;
	handleChange: any;
	onBlur?: any;
	style?: React.CSSProperties;
	inputStyle?: React.CSSProperties;
	autoFocus?: boolean;
	type?: "number" | "textarea";
	rows?: number;
	error?: string;
	touched?: boolean;
	min?: number;
	max?: number;
	withLabel?: boolean;
	label?: string;
}

export default function FormikInput(props: CustomInputProps) {
	const [inputType, setInputType] = useState(false);

	if (props.htmlFor === "password") {
		return (
			<div
				className="input"
				style={props.style}
			>
				{props.withLabel && props.label
					? <label htmlFor={props.htmlFor}>
						{props.label}
					</label>
					: null
				}
				<input
					style={props.inputStyle}
					autoFocus={props.autoFocus}
					name={props.name}
					type={!inputType ? "password" : "text"}
					placeholder={props.placeholder}
					value={props.value}
					onChange={props.handleChange}
					onBlur={props.onBlur}
				/>
				<img
					src={inputType ? eyeOpen : eyeClosed }
					style={!inputType ? props.withLabel ? {
							width: "20px",
							height: "16px",
							top: 34
						}
					: {
						width: "20px",
						height: "16px",
						top: 12
					} : {}}
					alt="hide"
					onClick={() => setInputType(!inputType)}
				/>
			</div>
		);
	} else {
		if (props.type === "number") {
			return (
				<div
					className="input"
					style={props.style}
				>
					{props.withLabel && props.label
						? <label htmlFor={props.htmlFor}>
							{props.label}
						</label>
						: null
					}
					<InputNumber
						placeholder={props.placeholder}
						disabled={props.disabled}
						value={props.value}
						onChange={props.handleChange}
						onBlur={props.onBlur}
						decimalSeparator={","}
						style={{
							...props.style,
							width: "100%",
							height: "100%",
							border: "1px solid #DBDFE4",
							borderRadius: "7px",
							display: "flex",
							alignItems: "center"
						}}
						min={props.min}
						max={props.max}
					/>
					{props.error && props.touched
						? <p className="input_error">{props.error}</p>
						: null
					}
				</div>
			);
		} else if (props.type === "textarea") {
			return(
				<div style={{...props.style, position: "relative"}}>
					<textarea
						style={props.inputStyle}
						name={props.name}
						rows={props.rows ? props.rows : 4}
						placeholder={props.placeholder}
						disabled={props.disabled}
						value={props.value}
						onChange={props.handleChange}
						onBlur={props.onBlur}
					/>
					{props.error && props.touched
						? <p className="input_error">{props.error}</p>
						: null
					}
				</div>
			);
		} else {
			return (
				<div
					className="input"
					style={props.style}
				>
					{props.withLabel && props.label
						? <label htmlFor={props.htmlFor}>
							{props.label}
						</label>
						: null
					}
					<input
						style={props.inputStyle}
						name={props.name}
						type="text"
						placeholder={props.placeholder}
						disabled={props.disabled}
						value={props.value}
						onChange={props.handleChange}
						onBlur={props.onBlur}
					/>
					{props.error && props.touched
						? <p className="input_error">{props.error}</p>
						: null
					}
				</div>
			);
		}
	}
};
