import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";
import {ContactsStatus, IAnswerContactData, IGetAllContactsParams, IGetAllContactsResponse} from "./contact.interface";

export default class ContactsService {
	constructor() {}

	getAll(query: IGetAllContactsParams): Promise<IGetAllContactsResponse> {
		return requestHandler({
			path: url.dashboard.contacts.getAll,
			method: "GET",
			protected: true,
			params: query
		}).then((res:any) => {
			return res.data
		}).catch((e: any) => {
			return {
				total: 0,
				data: []
			}
		})
	}

	changeStatus(contactId: string, status: ContactsStatus): Promise<{status: boolean}> {
		return requestHandler({
			path: `${url.dashboard.contacts.changeStatus}/${contactId}`,
			method: "PUT",
			protected: true,
			data: {
				status: status
			}
		}).then((res:any) => {
			if (res.status === 200) {
				return {
					status: true
				}
			} else {
				return {
					status: false
				}
			}
		}).catch((e: any) => {
			return {
				status: false
			}
		})
	}

	answer(data: IAnswerContactData): Promise<{status: boolean}> {
		return requestHandler({
			path: url.dashboard.contacts.answer,
			method: "PUT",
			protected: true,
			data: data
		}).then((res:any) => {
			if (res.status === 200) {
				return {
					status: true
				}
			} else {
				return {
					status: false
				}
			}
		}).catch((e: any) => {
			return {
				status: false
			}
		})
	}

}
