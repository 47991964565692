const environment = {
  baseUrl: "http://localhost:8000/"
};

switch (process.env.REACT_APP_ENV) {
case "development":
  environment.baseUrl = "https://api-dev.shary.at/";
  break;
case "production":
  environment.baseUrl = "https://api.shary.at/";
  break;
case "local":
  environment.baseUrl = "http://localhost:8000/";
  break;
default:
  environment.baseUrl = "http://localhost:8000/";
  break;
}

export default environment;
