import Input from "antd/lib/input/Input";
import {Button, Modal, notification, Pagination, Select, Table} from "antd";
import React, {useEffect, useState} from "react";
import ContactsService from "../../../../api/services/contactService/contact.service";
import {
	ContactData,
	ContactsStatus,
	IGetAllContactsParams
} from "../../../../api/services/contactService/contact.interface";
import moment from "moment";
import ContactAnswerModal from "../../../../components/Modals/ContactAnswerModal/ContactAnswerModal";
import {
	IGetAllReportsParams,
	ReportData,
	ReportStatus,
	ReportType
} from "../../../../api/services/reportsService/report.interface";
import ReportService from "../../../../api/services/reportsService/report.service";

export default function ReportsTab() {

	const [data, setData] = useState<null | {data: ContactData[], total: number}>(null);
	const [pageLimit, setPageLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
	const [selectedStatus, setSelectedStatus] = useState<ReportStatus>("all");
	const [selectedType, setSelectedType] = useState<ReportType>("all");
	const [selectedContact, setSelectedContact] = useState<null | ReportData>(null);

	useEffect(() => {
		getData({
			limit: pageLimit,
			skip: 0,
			status: selectedStatus,
			type: selectedType
		});
	}, []);

	const getData = async (query: IGetAllReportsParams) => {
		const response = await new ReportService().getAll(query);
		setData(response);
	};

	const onChange = (currentPage: number, pageSize: number) => {
		setPage(currentPage);
		setPageLimit(pageSize);
		getData({
			limit: pageSize,
			skip: pageSize * (currentPage - 1),
			search: search,
			status: selectedStatus,
			type: selectedType
		});
	}

	const onSearch = (e: any) => {
		e.preventDefault();
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search,
			status: selectedStatus,
			type: selectedType
		})
	}

	const columns = [
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email'
		},
		{
			title: 'Message',
			dataIndex: 'message',
			key: 'message',
			render: (message: string) => <p style={{margin: 0}}>
				{message.length > 20 ? message.slice(0, 20).concat('...') : message}
			</p>
		},
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type'
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status: ReportStatus, data: ReportData) => (
				<Select
					defaultValue={status}
					style={{ width: 120 }}
					onChange={(newStatus) => {
						onStatusChange(data._id, newStatus)
					}}
					options={[
						{ value: "received", label: "Received" },
						{ value: "in_work", label: "In work" },
						{ value: "answered", label: "Answered" }
					]}
				/>
			)
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (date: Date) => (
				<p>{moment(date).format("DD.MM.YYYY")}</p>
			)
		},
		{
			title: 'Action',
			dataIndex: '_id',
			key: '_id',
			render: (id: string, data: ReportData) => (
				<Button
					onClick={() => setSelectedContact(data)}
				>
					Answer
				</Button>
			)
		}
	];

	const handleSelectChange = (value: ReportStatus) => {
		setSelectedStatus(value);
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search,
			status: value,
			type: selectedType
		})
	}

	const handleTypeChange = (value: ReportType) => {
		setSelectedType(value);
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search,
			status: selectedStatus,
			type: value
		})
	}

	const onStatusChange = async (contactId: string, status: ReportStatus) => {
		const changeStatus = await new ReportService().changeStatus(contactId, status);
		if (!changeStatus?.status) {
			notification.error({
				message: "Something went wrong"
			});
		}
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search,
			status: selectedStatus,
			type: selectedType
		})
	}

	return(
		<div>
			<Modal
				visible={!!selectedContact}
				onCancel={() => setSelectedContact(null)}
				footer={null}
				width={800}
				closable={true}
				maskClosable={true}
			>
				<ContactAnswerModal
					selectedContact={selectedContact}
					onClose={() => {
						setSelectedContact(null);
						getData({
							limit: pageLimit,
							skip: pageLimit * (page - 1),
							search: search,
							status: selectedStatus,
							type: selectedType
						})
					}}
					type={"reports"}
				/>
			</Modal>
			{data
				&&
				<>
					<Select
						defaultValue="all"
						style={{ width: 120 }}
						onChange={handleSelectChange}
						options={[
							{ value: "all", label: "All" },
							{ value: "received", label: "Received" },
							{ value: "in_work", label: "In work" },
							{ value: "answered", label: "Answered" }
						]}
					/>
					<Select
						defaultValue="all"
						style={{ width: 120 }}
						onChange={handleTypeChange}
						options={[
							{ value: "all", label: "All" },
							{ value: "user", label: "User" },
							{ value: "article", label: "Article" }
						]}
					/>
					<form onSubmit={onSearch}>
						<Input
							placeholder="Search"
							onChange={(e) => setSearch(e.target.value)}
						/>
					</form>
					<Table
						rowKey="_id"
						dataSource={data.data}
						columns={columns}
						pagination={false}
					/>
					<Pagination
						total={data.total}
						showSizeChanger
						onShowSizeChange={onChange}
						onChange={onChange}
					/>
				</>
			}
		</div>
	);
}
