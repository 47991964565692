import React from "react";
import "./styles.css";
import DashboardContentBox from "../../../components/DashboardContentBox/DashboardContentBox.component";

export default function DashboardPage() {

	return(
		<DashboardContentBox>


		</DashboardContentBox>
	)
}
