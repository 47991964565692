import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, Input, notification} from "antd";
import {useState} from "react";
import KeywordsService from "../../../api/services/keywordsService/keywords.service";
import {ICreateKeywordResponse} from "../../../api/services/keywordsService/keywords.interface";

const { TextArea } = Input;

interface IProps {
	onClose: () => void;
}

export default function AddKeywordModal(props: IProps) {

	const [buttonLoading, setButtonLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: "",
			titleTag: "",
			descriptionTag: "",
			description: ""
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required()
		}),
		onSubmit: async () => {
			setButtonLoading(true);
			await new KeywordsService().create(formik.values)
				.then((res: ICreateKeywordResponse) => {
					if (res.status) {
						notification.success({
							message: "created"
						})
					} else {
						notification.error({
							message: "Error"
						})
					}
				})
				.catch((e: any) => {
					console.error(e);
					notification.error({
						message: "Error"
					})
				});
			setButtonLoading(false);
			formik.resetForm();
			props.onClose();
		},
	});

	return(
		<div>
			<h2>Add keyword</h2>

			<form onSubmit={formik.handleSubmit}>

				<p style={{marginBottom: 0}}>
					Keyword name *required
				</p>
				<Input
					value={formik.values.name}
					name={"name"}
					onChange={formik.handleChange}
				/>

				<p style={{marginBottom: 0}}>
					Title tag
				</p>
				<Input
					value={formik.values.titleTag}
					name={"titleTag"}
					onChange={formik.handleChange}
				/>

				<p style={{marginBottom: 0}}>
					Description tag
				</p>
				<Input
					value={formik.values.descriptionTag}
					name={"descriptionTag"}
					onChange={formik.handleChange}
				/>

				<p style={{marginBottom: 0}}>
					Content
				</p>
				<TextArea
					rows={16}
					value={formik.values.description}
					name={"description"}
					onChange={formik.handleChange}
				/>

				<Button
					htmlType={"submit"}
					disabled={buttonLoading}
				>
					{buttonLoading
						? "Loading..."
						: "Submit"
					}
				</Button>

			</form>

		</div>
	)
}
