import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";
import {IGetSubCategoryByParentPath} from "./subCategoryService.interface";

export default class SubCategoriesService {
	constructor() {}

	async getByParentPath(path: string): Promise<IGetSubCategoryByParentPath> {
		return await requestHandler({
			path: `${url.dashboard.subCategories.getByParentPath}/${path}`,
			method: "GET",
		}).then((res: any) => {
			return {
				status: true,
				data: res.data
			}
		}).catch((e: any) => {
			return {
				status: false,
				data: e.response.data
			}
		});
	}
}
