import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";
import {IAnswerReportData, IGetAllReportsParams, IGetAllReportsResponse, ReportStatus} from "./report.interface";

export default class ReportService {
	constructor() {}

	getAll(query: IGetAllReportsParams): Promise<IGetAllReportsResponse> {
		return requestHandler({
			path: url.dashboard.reports.getAll,
			method: "GET",
			protected: true,
			params: query
		}).then((res:any) => {
			return res.data
		}).catch((e: any) => {
			return {
				total: 0,
				data: []
			}
		})
	}

	changeStatus(reportId: string, status: ReportStatus): Promise<{status: boolean}> {
		return requestHandler({
			path: `${url.dashboard.reports.changeStatus}/${reportId}`,
			method: "PUT",
			protected: true,
			data: {
				status: status
			}
		}).then((res:any) => {
			if (res.status === 200) {
				return {
					status: true
				}
			} else {
				return {
					status: false
				}
			}
		}).catch((e: any) => {
			return {
				status: false
			}
		})
	}

	answer(data: IAnswerReportData): Promise<{status: boolean}> {
		return requestHandler({
			path: url.dashboard.reports.answer,
			method: "PUT",
			protected: true,
			data: data
		}).then((res:any) => {
			if (res.status === 200) {
				return {
					status: true
				}
			} else {
				return {
					status: false
				}
			}
		}).catch((e: any) => {
			return {
				status: false
			}
		})
	}

}
