import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";
import {
	getAllCheckoutsQuery,
	getCheckoutsStatisticQuery,
	IGetAllCheckoutsResponse,
	IGetCheckoutsStatisticResponse
} from "./checkout.interface";

export default class CheckoutService {
	constructor() {}

	getAll(query: getAllCheckoutsQuery): Promise<IGetAllCheckoutsResponse> {
		return requestHandler({
			path: url.dashboard.checkouts.getAll,
			method: "GET",
			protected: true,
			params: query
		}).then((res:any) => {
			return res.data
		}).catch((e: any) => {
			return {
				status: false
			}
		})
	}

	getStatistic(query: getCheckoutsStatisticQuery): Promise<IGetCheckoutsStatisticResponse> {
		return requestHandler({
			path: url.dashboard.checkouts.getAll,
			method: "GET",
			protected: true,
			params: query
		}).then((res:any) => {
			return res.data
		}).catch((e: any) => {
			return {
				status: false
			}
		})
	}

}
