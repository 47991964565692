interface IProps {
	imageUrl: string;
}

export default function ImageGalleryThumbnail(props:IProps) {
	return(
		<img
			className="image_gallery_thumbnail"
			src={props.imageUrl}
			alt={props.imageUrl}
		/>
	)
}
