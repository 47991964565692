import "./PaymentsList.css";
import {Pagination, Select, Table} from "antd";
import {useEffect, useState} from "react";
import {
	IGetAllPaymentsParams,
	paymentsStatus
} from "../../../../api/services/paymentsService/paymentsService.interface";
import PaymentsService from "../../../../api/services/paymentsService/paymentsService.service";
import {currencyFormatter} from "../../../../utils/currencyFormatter";
import moment from "moment";

export default function PaymentsList() {

	const [data, setData] = useState<null | {data: any[], total: number}>(null);
	const [pageLimit, setPageLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [selectedStatus, setSelectedStatus] = useState<paymentsStatus>("all");

	useEffect(() => {
		getData({
			limit: pageLimit,
			skip: 0,
			status: selectedStatus
		});
	}, []);

	const getData = async (params: IGetAllPaymentsParams) => {
		const response = await new PaymentsService().getAll(params);
		setData(response);
	};

	const onChange = (currentPage: number, pageSize: number) => {
		setPage(currentPage);
		setPageLimit(pageSize);
		getData({
			limit: pageSize,
			skip: pageSize * (currentPage - 1),
			status: selectedStatus
		});
	}

	const columns = [
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (amount: number) => <p>
				{currencyFormatter({
					amount: amount,
					currency: "EUR"
				})}
			</p>
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Receiver email',
			dataIndex: 'receiver',
			key: 'receiver',
			render: (receiver: any) => <p>
				{receiver.email}
			</p>
		},
		{
			title: 'Receiver first name',
			dataIndex: 'receiver',
			key: 'receiver',
			render: (receiver: any) => <p>
				{receiver.firstName}
			</p>
		},
		{
			title: 'Receiver last name',
			dataIndex: 'receiver',
			key: 'receiver',
			render: (receiver: any) => <p>
				{receiver.lastName}
			</p>
		},
		{
			title: 'Sender email',
			dataIndex: 'sender',
			key: 'sender',
			render: (sender: any) => <p>
				{sender.email}
			</p>
		},
		{
			title: 'Sender first name',
			dataIndex: 'sender',
			key: 'sender',
			render: (sender: any) => <p>
				{sender.firstName}
			</p>
		},
		{
			title: 'Sender last name',
			dataIndex: 'sender',
			key: 'sender',
			render: (sender: any) => <p>
				{sender.lastName}
			</p>
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: any) => <p>
				{moment(createdAt).format("DD.MM.YYYY")}
			</p>
		}
	];

	const handleSelectChange = (value: paymentsStatus) => {
		setSelectedStatus(value);
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			status: value
		})
	}

	return(
		<div>
			{data
				&&
				<>
					<Select
						defaultValue="all"
						style={{ width: 320 }}
						onChange={handleSelectChange}
						options={[
							{ value: "all", label: "All" },
							{ value: "blockedInCustomerCard", label: "Blocked in customer card" },
							{ value: "transferredBackToCustomer", label: "Transferred back to customer" },
							{ value: "transferredToStripe", label: "Transferred to stripe" },
							{ value: "transferredToCompanyAccount", label: "Transferred to company account" },
						]}
					/>
					<Table
						rowKey="_id"
						dataSource={data.data}
						columns={columns}
						pagination={false}
					/>
					<Pagination
						total={data.total}
						showSizeChanger
						onShowSizeChange={onChange}
						onChange={onChange}
					/>
				</>
			}
		</div>
	);
}
