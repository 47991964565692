export const rejectModalOptions = {
	bad_picture_quality: {
		key: "Dab picture quality",
		title: "Ablehnung des Artikels aufgrund schlechter Bildqualität",
		comment: "es tut uns leid, Ihnen mitteilen zu müssen, dass wir Ihren Artikel aufgrund der schlechten Bildqualität leider nicht annehmen können. Wir bitten Sie daher, neue Bilder von höherer Qualität hochzuladen. Sobald die Bilder aktualisiert sind, würden wir uns freuen, Ihre Artikel auf unserer Plattform präsentieren zu können. Falls Sie der Meinung sind, dass die Ursache für die schlechte Bildqualität bei Shary liegt, zögern Sie bitte nicht, uns unter support@shary.at zu kontaktieren. Wir stehen Ihnen gerne zur Verfügung, um zu helfen."
	},
	picture_do_not_represent_a_product: {
		key: "Picture do not represent a product",
		title: "Ablehnung des Artikels aufgrund unzureichender Produktabbildungen",
		comment: "wir bedauern Ihnen mitteilen zu müssen, dass wir Ihren Artikel aufgrund unzureichender Produktabbildungen nicht akzeptieren können. Wir möchten Sie daher bitten, neue Bilder hochzuladen, die das Produkt, das Sie vermieten möchten, angemessen repräsentieren. Sobald die neuen Abbildungen vorliegen, freuen wir uns darauf, Ihre Artikel auf unserer Plattform anzubieten. Sollten Sie der Ansicht sein, dass die Ursache für die unzureichende Darstellung bei Shary liegt, zögern Sie bitte nicht, uns unter support@shary.at zu kontaktieren. Wir stehen Ihnen gerne zur Verfügung, um zu helfen."
	},
	deposit_is_not_allowed: {
		key: "Deposit not allowed",
		title: "Ablehnung des Artikels aufgrund der Kaution",
		comment: "wir bedauern Ihnen mitteilen zu müssen, dass wir Ihren Artikel nicht aufnehmen können, da wir unseren Kunden einen Service anbieten möchten, bei dem keine Kaution erforderlich ist. Wir stellen eine Versicherung für unsere Verleiher bereit, um dies zu gewährleisten. Bitte passen Sie Ihre Beschreibung entsprechend an, und wir würden uns freuen, Ihre Artikel auf unserer Plattform zu präsentieren. Falls Sie Fragen haben, zögern Sie bitte nicht, uns unter support@shary.at zu kontaktieren. Wir stehen Ihnen gerne zur Verfügung."
	}
};
