import {ContactData} from "../../../api/services/contactService/contact.interface";
import {Button, Input, notification} from "antd";
import {useState} from "react";
import ContactsService from "../../../api/services/contactService/contact.service";
import ReportService from "../../../api/services/reportsService/report.service";

const { TextArea } = Input;

interface IProps {
	selectedContact: ContactData | null;
	onClose: () => void;
	type: "contact" | "reports";
}

export default function ContactAnswerModal(props: IProps) {
	const [answer, setAnswer] = useState("");
	const [buttonLoading, setButtonLoading] = useState(false);

	const handleAnswer = async () => {
		if (props.selectedContact?._id) {
			setButtonLoading(true);
			let answerStatus;
			if (props.type === "contact") {
				answerStatus = await new ContactsService().answer({
					id: props.selectedContact._id,
					email: props.selectedContact.email,
					message: answer
				});
			} else {
				answerStatus = await new ReportService().answer({
					id: props.selectedContact._id,
					email: props.selectedContact.email,
					message: answer
				});
			}

			setButtonLoading(false);
			if (!answerStatus.status) {
				notification.error({
					message: "Something went wrong"
				})
			} else {
				setAnswer("");
				notification.success({
					message: "Message sent"
				})
				setTimeout(() => {
					props.onClose()
				}, 1000);
			}
		}
	}

	return(
		<div>
			<h2>
				{props.selectedContact?.name}
			</h2>
			<h2>
				{props.selectedContact?.email}
			</h2>
			<h3>
				Message: {props.selectedContact?.message}
			</h3>


			<p>Answer:</p>
			<TextArea
				value={answer}
				onChange={(e: any) => setAnswer(e.target.value)}
				rows={6}
			/>

			<Button
				onClick={() => handleAnswer()}
				disabled={buttonLoading}
			>
				{buttonLoading
					? "Sending..."
					: "Answer"
				}
			</Button>

		</div>
	);
}
