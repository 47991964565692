import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import App from "./App";
import "./i18next";
import 'antd/dist/antd.css';
import { PersistGate } from 'redux-persist/integration/react';

import configureStore from './redux/store';

const { persistor, store } = configureStore();

ReactDOM.render(
  <Provider store={store}>
	  <PersistGate persistor={persistor} loading={null}>
		  <React.StrictMode>
			  {/* Show loading until languages will be not initialized */}
			  <Suspense fallback={<p>Loading</p>}>
				  <App />
			  </Suspense>
		  </React.StrictMode>
	  </PersistGate>
  </Provider>,
  document.getElementById("root")
);
