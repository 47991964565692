import axios from "axios";
import environment from "../environment";
import {getToken} from "./cookie/token.cookie";
import {apiErrorHandler} from "./apiErrorHandler";

interface IRequest {
	path: string;
	method: "GET" | "POST" | "PUT" | "DELETE";
	data?: any;
	params?: any;
	showErrorNotification?: boolean;
	protected?: boolean;
}

export async function requestHandler(props:IRequest) {
  try {
  	let tokenData:any;
  	if (props.protected) {
      tokenData = await getToken()
    }
    const response = await axios({
      url: `${environment.baseUrl}${props.path}`,
      method: props.method,
      params: props.params,
      data: props.data,
      headers: props.protected ? { Authorization: `Bearer ${tokenData}` } : {},
    });
    return {
      status: response.status,
      data: response.data
    };
  } catch (e: any) {
    return apiErrorHandler(e.response.status, e, props.showErrorNotification);
  }
}
