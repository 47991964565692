interface IProps {
	imageUrl: string;
	editable?: boolean;
	deleteImage?: (imageUrl: string) => void;
	newImageAdded?: (imageUrl: string) => void;
}

export default function ImageGalleryImage(props:IProps) {

	return(
		<div
			style={{position: "relative", width: "100%", height: "100%"}}
			className="image_gallery_arrow_container_edit_container_hover"
		>
			<img
				className="image_gallery_image"
				src={props.imageUrl}
				alt={props.imageUrl}
			/>
		</div>
	)
}
