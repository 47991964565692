import React from "react";
import { Tabs } from 'antd';
import SearchKeysTab from "../Tabs/SearchKeysTab/SearchKeysTab";
import CategoryKeywordsTab from "../Tabs/CategoryKeywordsTab/CategoryKeywordsTab";
import KeywordsTab from "../Tabs/KeywordsTab/KeywordsTab";

const { TabPane } = Tabs;

export default function MainKeywordsPage() {

	return(
		<div className="page">
			<Tabs>
				<TabPane tab="Category keywords" key="categoryKeywords">
					<CategoryKeywordsTab />
				</TabPane>
				<TabPane tab="Keywords" key="keywords">
					<KeywordsTab />
				</TabPane>
				<TabPane tab="Search Keys" key="searchKeys">
					<SearchKeysTab />
				</TabPane>
			</Tabs>
		</div>
	)
}
