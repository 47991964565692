import "./OrdersList.css";
import Input from "antd/lib/input/Input";
import {Pagination, Select, Table} from "antd";
import {useEffect, useState} from "react";
import moment from "moment/moment";
import {currencyFormatter} from "../../../../utils/currencyFormatter";
import {IGetAllOrdersParams, orderStatusType} from "../../../../api/services/ordersService/orders.interface";
import OrdersService from "../../../../api/services/ordersService/orders.service";

export default function OrdersList() {

	const [data, setData] = useState<null | {data: any[], total: number}>(null);
	const [pageLimit, setPageLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
	const [selectedStatus, setSelectedStatus] = useState<orderStatusType>("all");

	useEffect(() => {
		getData({
			limit: pageLimit,
			skip: 0,
			status: selectedStatus
		});
	}, []);

	const getData = async (params: IGetAllOrdersParams) => {
		const response = await new OrdersService().getAll(params);
		setData(response);
	};

	const onChange = (currentPage: number, pageSize: number) => {
		setPage(currentPage);
		setPageLimit(pageSize);
		getData({
			limit: pageSize,
			skip: pageSize * (currentPage - 1),
			search: search,
			status: selectedStatus
		});
	}

	const onSearch = (e: any) => {
		e.preventDefault();
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search,
			status: selectedStatus
		})
	}

	const columns = [
		{
			title: 'Post Title',
			dataIndex: 'postTitle',
			key: 'postTitle',
			render: (postTitle: string) => <p>
				{postTitle.length > 20 ? postTitle.slice(0, 20).concat('...') : postTitle}
			</p>
		},
		{
			title: 'Paid amount',
			dataIndex: 'paidAmount',
			render: (amount: number) => <p>
				{currencyFormatter({
					amount: amount,
					currency: "EUR"
				})}
			</p>
		},
		{
			title: 'Shary fee',
			dataIndex: 'platformFee',
			key: 'platformFee',
			render: (amount: number) => <p>
				{currencyFormatter({
					amount: amount,
					currency: "EUR"
				})}
			</p>
		},
		{
			title: 'Start date',
			dataIndex: 'firstDayOfRental',
			key: 'firstDayOfRental',
			render: (startDate: Date) => <p>
				{moment(startDate).format("DD.MM.YYYY")}
			</p>
		},
		{
			title: 'End date',
			dataIndex: 'lastDayOfRental',
			key: 'lastDayOfRental',
			render: (endDate: Date) => <p>
				{moment(endDate).format("DD.MM.YYYY")}
			</p>
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Device',
			dataIndex: 'device',
			key: 'device',
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (date: Date) => <p>
				{moment(date).format("DD.MM.YYYY")}
			</p>
		}
	];

	const handleSelectChange = (value: orderStatusType) => {
		setSelectedStatus(value);
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search,
			status: value
		})
	}

	console.log(data)

	return(
		<div>
			{data
				&&
				<>
					<Select
						defaultValue="all"
						style={{ width: 120 }}
						onChange={handleSelectChange}
						options={[
							{ value: "all", label: "All" },
							{ value: "requested", label: "Requested" },
							{ value: "approved", label: "Approved" },
							{ value: "rejected", label: "Rejected" },
							{ value: "declinedByCustomer", label: "Declined by customer" },
							{ value: "rentProcessing", label: "Rent processing" },
							{ value: "completed", label: "Completed" },
							{ value: "dispute", label: "Dispute" }
						]}
					/>
					<form onSubmit={onSearch}>
						<Input
							placeholder="Search"
							onChange={(e) => setSearch(e.target.value)}
						/>
					</form>
					<Table
						rowKey="_id"
						dataSource={data.data}
						columns={columns}
						pagination={false}
					/>
					<Pagination
						total={data.total}
						showSizeChanger
						onShowSizeChange={onChange}
						onChange={onChange}
					/>
				</>
			}
		</div>
	);
}
