import {useTranslation} from "react-i18next";
import {Tabs} from "antd";
import React from "react";
import ContactTab from "../ContactTab/ContactTab";
import ReportsTab from "../ReportsTab/ReportsTab";

const { TabPane } = Tabs;

export default function ContactsPage() {
	const { t } = useTranslation();

	return(
		<div
			className="page"
		>
			<h1>Orders</h1>

			<Tabs>
				<TabPane tab="Dashboard" key="dashboard">
					{/*<AllOrdersPage />*/}
				</TabPane>
				<TabPane tab="Contacts" key="contacts">
					<ContactTab />
				</TabPane>
				<TabPane tab="Reports" key="reports">
					<ReportsTab />
				</TabPane>
			</Tabs>

		</div>
	)
}
