import {Pagination, Select, Table} from "antd";
import {useEffect, useState} from "react";
import moment from "moment";
import {IGetAllPosts, IPost} from "../../../api/services/PostsService/posts.service.interface";
import PostsService from "../../../api/services/PostsService/posts.service";

export default function AllPostsPage() {

	const [data, setData] = useState<null | {data: IPost[], count: number}>(null);
	const [pageLimit, setPageLimit] = useState(10);
	const [page, setPage] = useState(1);

	useEffect(() => {
		getData({
			limit: pageLimit,
			skip: 0
		});
	}, []);

	const getData = async (params: IGetAllPosts) => {
		const response = await new PostsService().getAllPosts(params);

		if (response && response.status && response.data) {
			setData(response.data);
		}
	};

	const onChange = (currentPage: number, pageSize: number) => {
		setPage(currentPage);
		setPageLimit(pageSize);
		getData({
			limit: pageSize,
			skip: pageSize * (currentPage - 1)
		});
	}

	console.log(data)

	const columns = [
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			render: (title: string) => <p>
				{title.length > 40 ? title.slice(0, 40).concat('...') : title}
			</p>
		},
		{
			title: 'Author',
			dataIndex: 'owner',
			key: 'owner',
			render: (owner: any[]) => <p>
				{`${owner[0].firstName} ${owner[0].lastName}`}
			</p>
		},
		{
			title: 'City',
			dataIndex: 'city',
			key: 'city'
		},
		{
			title: 'Price',
			dataIndex: 'pricing',
			key: 'pricing',
			render: (pricing: any) => <p>
				{`${pricing.daily["1"]} | ${pricing.daily["2-6"]} | ${pricing.daily["7-30"]} | ${pricing.daily["30+"]}`}
			</p>
		},
		{
			title: 'Created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: string) => <p>
				{moment(createdAt).format("DD.MM - HH:mm")}
			</p>
		},
		{
			title: 'Path',
			dataIndex: 'path',
			key: 'path'
		},
		{
			title: 'Approved',
			dataIndex: 'approved',
			key: 'approved',
			render: (state: boolean) => <p>{state.toString()}</p>
		},
	];

	return(
		<div>
			{data
				&&
				<>
					<Table
						rowKey="_id"
						dataSource={data.data}
						columns={columns}
						pagination={false}
					/>
					<Pagination
						total={data.count}
						showSizeChanger
						onShowSizeChange={onChange}
						onChange={onChange}
					/>
				</>
			}
		</div>
	);
}
