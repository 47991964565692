import {
	IGetAllCategoriesAndSubCategoriesResponse,
	IGetAllCategoriesResponse,
	IUpdateCategoriesKeywordsRequest
} from "./category.interface";
import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";

export default class CategoriesService {
	constructor() {}

	async getAllCategories(): Promise<IGetAllCategoriesResponse> {
		return await requestHandler({
			path: url.dashboard.categories.getAll,
			method: "GET",
		}).then((res: any) => {
			return {
				status: true,
				data: res.data
			}
		}).catch((e: any) => {
			return {
				status: false,
				data: e.response.data
			}
		});
	}

	async getAllCategoriesAndSubCategories(): Promise<IGetAllCategoriesAndSubCategoriesResponse> {
		return await requestHandler({
			path: url.dashboard.categories.getAllCategoriesAndSubCategories,
			method: "GET",
		}).then((res: any) => {
			return {
				status: true,
				data: res.data
			}
		}).catch((e: any) => {
			return {
				status: false,
				data: e.response.data
			}
		});
	}

	async updateCategoriesKeywords(data: IUpdateCategoriesKeywordsRequest): Promise<any> {
		return await requestHandler({
			path: url.dashboard.categories.updateCategoriesKeywords,
			method: "PUT",
			data: data,
			protected: true
		}).then((res: any) => {
			return {
				status: true
			}
		}).catch((e: any) => {
			return {
				status: false
			}
		});
	}

}
