import {IGetAllSearchKeysRequestParams} from "./searchKeys.interface";
import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";

export default class SearchKeysService {
	constructor() {}

	async getAll(params: IGetAllSearchKeysRequestParams) {
		return await requestHandler({
			path: url.dashboard.searchKeys.getAll,
			method: "GET",
			params: params,
			protected: true
		}).then((res: any) => {
			return {
				status: true,
				data: res.data
			}
		}).catch((e: any) => {
			return {
				status: false,
				data: e.response.data
			}
		});
	}

	async delete(id: string) {
		return await requestHandler({
			path: `${url.dashboard.searchKeys.delete}/${id}`,
			method: "DELETE",
			protected: true
		}).then((res: any) => {
			return {
				status: true
			}
		}).catch((e: any) => {
			return {
				status: false
			}
		});
	}

}
