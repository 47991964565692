import React from "react";
import {Route, Routes} from "react-router-dom";
import AllOrdersPage from "./AllOrdersPage/AllOrdersPage";

export default function OrdersRouter() {

	return(
		<Routes>
			<Route path="/" element={<AllOrdersPage />} />
		</Routes>
	)
}
