import "./KeywordSeoDescriptionModal.css";
import {IKeywordData} from "../../../api/services/keywordsService/keywords.interface";
import {useEffect, useState} from "react";
import {Button, Input} from "antd";
import KeywordsService from "../../../api/services/keywordsService/keywords.service";

interface IProps {
	data: IKeywordData | null;
	close: () => void;
}

export default function KeywordSeoDescriptionModal(props: IProps) {
	const [metaTitleValue, setMetaTitleValue] = useState("");
	const [metaDescriptionValue, setMetaDescriptionValue] = useState("");
	const [value, setValue] = useState("");

	useEffect(() => {
		if (props.data?.description) {
			setValue(props.data.description);
			setMetaTitleValue(props.data.titleTag || "");
			setMetaDescriptionValue(props.data.descriptionTag || "");
		}
	}, [props.data])

	const onChange = (event: any) => {
		event.preventDefault();
		setValue(event.target.value)
	}

	const onSave = () => {
		if (props.data?._id) {
			new KeywordsService().addDescriptionToKeyword({
				id: props.data?._id,
				description: value,
				titleTag: metaTitleValue,
				descriptionTag: metaDescriptionValue
			}).then(() => props.close());
		}
	}

	return(
		props.data &&
		<div style={{display: "flex", flexDirection: "column"}}>
			<p>
				Keyword: {props.data.name}
			</p>


			<p style={{marginBottom: 0}}>meta-title (50–60 characters), current: {metaTitleValue.length}</p>
			<Input
				value={metaTitleValue}
				onChange={(e:any) => setMetaTitleValue(e.target.value)}
			/>

			<p style={{marginBottom: 0}}>meta-description (155-160 characters), current: {metaTitleValue.length}</p>
			<Input
				value={metaDescriptionValue}
				onChange={(e:any) => setMetaDescriptionValue(e.target.value)}
			/>

			<textarea
				rows={10}
				value={value}
				onChange={onChange}
				style={{
					minWidth: "700px",
					height: "400px"
				}}
			/>

			<Button
				onClick={() => onSave()}
			>
				Save
			</Button>

		</div>
	)
}
