import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";
import {IGetAllOrdersResponse, IGetAllPaymentsParams} from "./paymentsService.interface";

export default class PaymentsService {
	constructor() {}

	async getAll(params: IGetAllPaymentsParams): Promise<IGetAllOrdersResponse> {
		return await requestHandler({
			path: url.dashboard.payments.getAll,
			method: "GET",
			params: params,
			protected: true
		}).then((res: any) => {
			return res.data
		}).catch((e: any) => {
			return {
				total: 0,
				data: []
			}
		});
	}

}
