import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";
import {IGetAllMessagesParams, IGetAllMessagesResponse} from "./messages.interface";

export default class MessagesService {
	constructor() {}

	async getAll(params: IGetAllMessagesParams): Promise<IGetAllMessagesResponse> {
		return await requestHandler({
			path: url.dashboard.messages.getAll,
			method: "GET",
			params: params,
			protected: true
		}).then((res: any) => {
			return {
				status: true,
				data: res.data
			}
		}).catch((e: any) => {
			return {
				status: false,
				data: {
					total: 0,
					data: []
				}
			}
		});
	}

}
