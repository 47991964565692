import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../urls";
import {IGetAllOutgoingPaymentsParams, IGetAllOutgoingPaymentsResponse} from "./outgoingPayments.interface";

export default class OutgoingPaymentsService {
	constructor() {}

	async getAll(params: IGetAllOutgoingPaymentsParams): Promise<IGetAllOutgoingPaymentsResponse> {
		return await requestHandler({
			path: url.dashboard.outgoingPayments.getAll,
			method: "GET",
			params: params,
			protected: true
		}).then((res: any) => {
			return res.data
		}).catch((e: any) => {
			return {
				total: 0,
				data: []
			}
		});
	}

}
