import "./UsersList.css";
import Input from "antd/lib/input/Input";
import {Pagination, Select, Table} from "antd";
import {useEffect, useState} from "react";
import moment from "moment/moment";
import {IGetAllUsersParams} from "../../../../../api/services/usersService/users.interface";
import UsersService from "../../../../../api/services/usersService/users.service";

export default function UsersList() {

	const [data, setData] = useState<null | {data: any[], total: number}>(null);
	const [pageLimit, setPageLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");

	useEffect(() => {
		getData({
			limit: pageLimit,
			skip: 0
		});
	}, []);

	const getData = async (params: IGetAllUsersParams) => {
		const response = await new UsersService().getAll(params);
		setData(response);
	};

	const onChange = (currentPage: number, pageSize: number) => {
		setPage(currentPage);
		setPageLimit(pageSize);
		getData({
			limit: pageSize,
			skip: pageSize * (currentPage - 1),
			search: search
		});
	}

	const onSearch = (e: any) => {
		e.preventDefault();
		getData({
			limit: pageLimit,
			skip: pageLimit * (page - 1),
			search: search
		})
	}

	const columns = [
		{
			title: 'First name',
			dataIndex: 'firstName',
			key: 'firstName'
		},
		{
			title: 'Last name',
			dataIndex: 'lastName',
			key: 'lastName'
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email'
		},
		{
			title: 'Device',
			dataIndex: 'device',
			key: 'device'
		},
		{
			title: 'Active',
			dataIndex: 'active',
			key: 'active',
			render: (active: boolean) => <p>
				{active ? "Active" : "Not" }
			</p>
		},
		{
			title: 'activated',
			dataIndex: 'activated',
			key: 'activated',
			render: (activated: boolean) => <p>
				{activated ? "Activated" : "Not" }
			</p>
		},
		{
			title: 'createdAt',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: Date) => <p>
				{moment(createdAt).format("DD.MM.YYYY")}
			</p>
		}
	];

	return(
		<div>
			{data
				&&
				<>
					<form onSubmit={onSearch}>
						<Input
							placeholder="Search"
							onChange={(e) => setSearch(e.target.value)}
						/>
					</form>
					<Table
						rowKey="_id"
						dataSource={data.data}
						columns={columns}
						pagination={false}
					/>
					<Pagination
						total={data.total}
						showSizeChanger
						onShowSizeChange={onChange}
						onChange={onChange}
					/>
				</>
			}
		</div>
	);
}
