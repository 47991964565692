import React, {useEffect, useState} from "react";
import "./styles.css";
import DashboardContentBox from "../../../components/DashboardContentBox/DashboardContentBox.component";
import { useParams } from 'react-router';
import PostsService from "../../../api/services/PostsService/posts.service";
import Button from "../../../components/Button/Button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Card, Checkbox, Modal, notification, Select, Space} from "antd";
import ImageGalleryComponent from "../../../components/ImageGallery/ImageGallery";
import moment from "moment";
import RejectPostModal, {rejectionFormType} from "../../../components/Modals/RejectPostModal/RejectPostModal";
import CategoriesService from "../../../api/services/categoryService/category.service";
import dataToValueTransformer from "../../../utils/dataToOptionsTransformer";
import SubCategoriesService from "../../../api/services/subCategoryService/subCategoryService.service";


export default function PostDetail() {
	const { id } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [dataLoading, setDataLoading] = useState(false);
	const [data, setData] = useState<any>({});
	const [userPosts, setUserPosts] = useState<any>([]);
	const [showPostIsAlreadyApprovedState, setShowPostIsAlreadyApprovedState] = useState(false);
	const [prevPostId, setPrevPostId] = useState<null | {_id: string}>(null);
	const [nextPostId, setNextPostId] = useState<null | {_id: string}>(null);
	const [approvalButtonLoading, setApprovalButtonLoading] = useState(false);
	const [rejectionButtonLoading, setRejectionButtonLoading] = useState(false);
	const [showRejectModal, setShowRejectModal] = useState(false);
	const [categoriesData, setCategoriesData] = useState<{value: string, label: string}[]>([{
		label: "",
		value: ""
	}]);
	const [subCategoriesData, setSubCategoriesData] = useState<{value: string, label: string}[]>([{
		label: "",
		value: ""
	}]);
	const [selectedCategory, setSelectedCategory] = useState({
		label: data.category,
		value: data.categoryPath
	});
	const [selectedSubCategory, setSelectedSubCategory] = useState({
		label: data.subCategory,
		value: data.subCategoryPath
	});

	useEffect(() => {
		getData();
	}, [id]);

	const getData = async () => {
		if (id) {
			setDataLoading(true)
			const data = await new PostsService().getOnePostForApproval(id);
			const categoriesData = await new CategoriesService().getAllCategories();
			const transformedValues = dataToValueTransformer({
				data: categoriesData.data,
				label: "name.de",
				value: "path"
			});
			setCategoriesData(transformedValues);
			if (data.status) {
				if (data.data.currentPost.approved) {
					// Show post is approved
					setShowPostIsAlreadyApprovedState(true);
				} else {
					setData(data.data.currentPost);
					setPrevPostId(data.data.prevPost);
					setNextPostId(data.data.nextPost);
					setSelectedCategory({
						label: data.data.currentPost.category,
						value: data.data.currentPost.categoryPath
					});
					setSelectedSubCategory({
						label: data.data.currentPost.subCategory,
						value: data.data.currentPost.subCategoryPath
					});
					// Get initial list of Sub Categories
					getSubCategoriesData(data.data.currentPost.categoryPath);
					setUserPosts(data.data.allPostsByUser);
				}
			}
			setDataLoading(false)
		}
	}

	const getSubCategoriesData = async (categoryPath: string) => {
		const subCategoriesData = await new SubCategoriesService().getByParentPath(categoryPath);
		const transformedValues = dataToValueTransformer({
			data: subCategoriesData.data,
			label: "name.de",
			value: "path"
		});
		setSubCategoriesData(transformedValues);
	}

	const approvePost = async () => {
		if (id) {
			setApprovalButtonLoading(true);
			const result = await new PostsService().approve(id);
			if (result?.status) {
				notification.success({
					message: "Post has been APPROVED"
				});
				setTimeout(() => {
					// Move to next post
					if (nextPostId?._id) {
						navigate(`/postsForApproval/${nextPostId._id}`)
					} else {
						// Move to list page
						navigate("/postsForApproval")
					}
				}, 1000)
			} else {
				notification.error({
					message: result.data
				});
			}
			setApprovalButtonLoading(false);
		}
	}

	const rejectPost = async (rejectionData: rejectionFormType) => {
		if (id) {
			setShowRejectModal(false);
			setRejectionButtonLoading(true);
			const result = await new PostsService().reject({
				postId: id,
				reason: rejectionData.reason,
				comment: rejectionData.comment
			});
			if (result?.status) {
				notification.success({
					message: "Post has been REJECTED"
				});
				setTimeout(() => {
					// Move to next post
					if (nextPostId?._id) {
						navigate(`/postsForApproval/${nextPostId._id}`)
					} else {
						// Move to list page
						navigate("/postsForApproval")
					}
				}, 1000)
			} else {
				notification.error({
					message: result.data
				});
			}
			setRejectionButtonLoading(false);
		}
	}

	const handleCategorySelect = async (value: any, obj: any) => {
		setSelectedCategory(obj);
		// Clear selected sub category
		setSelectedSubCategory({
			label: "",
			value: ""
		})
		// Get sub categories for selected parent category
		getSubCategoriesData(obj.value);
	}

	const handleSubCategorySelect = async (value: any, obj: any) => {
		const updateResponse = await new PostsService().updatePost(data.path, {
			...data,
			category: selectedCategory.value,
			subCategory: obj.value
		})
		if (updateResponse && updateResponse.status) {
			notification.success({
				message: "Updated"
			})
			getData();
		} else {
			notification.error({
				message: `Something went wrong on server ${updateResponse.data}`
			})
		}
	}

	const switchInsuredValue = async () => {
		const response = await new PostsService().switchInsurance({
			postId: data._id,
			insured: !data.insured
		})
		notification.success({
			message: "Updated"
		});
		getData();
	}

	return(
		<DashboardContentBox>

			{/*Reject Modal*/}
			<Modal
				visible={showRejectModal}
				onCancel={() => setShowRejectModal(false)}
				footer={null}
				width={800}
				closable={true}
				maskClosable={true}
			>
				<RejectPostModal
					onRejection={(rejectionData: rejectionFormType) => {
						rejectPost(rejectionData);
					}}
				/>
			</Modal>

			{showPostIsAlreadyApprovedState
				? <div className="center">
					<h1>
						Already approved
					</h1>
				</div>
				: <>
					{dataLoading || Object.keys(data).length < 1
						? <h1>Loading</h1>
						: <>
							<div className="space-between">
								<Button
									text={"Previous"}
									styleType={"outlined"}
									disabled={!prevPostId}
									onClick={() => {
										if (prevPostId?._id) {
											navigate(`/postsForApproval/${prevPostId._id}`)
										}
									}}
								/>

								<Button
									text={"Approve"}
									styleType={"primary"}
									onClick={() => {
										approvePost();
									}}
									loading={approvalButtonLoading}
									disabled={data.category === "default" || data.subCategory === "default"}
								/>

								<Button
									text={"Reject"}
									styleType={"primary"}
									onClick={() => {
										setShowRejectModal(true);
									}}
									loading={rejectionButtonLoading}
									disabled={data.category === "default" || data.subCategory === "default"}
								/>

								<Button
									text={"Next"}
									styleType={"outlined"}
									disabled={!nextPostId}
									onClick={() => {
										if (nextPostId?._id) {
											navigate(`/postsForApproval/${nextPostId._id}`)
										}
									}}
								/>
							</div>

							<div
								className="approvePosts_divider"
							>

								<ImageGalleryComponent
									loading={false}
									imagesUrls={data.imagesUrls}
									editable
								/>

								<div>

									{/*Insured*/}
									<Checkbox
										checked={data.insured}
										onChange={() => switchInsuredValue()}
									>
										<p style={{fontSize: "12px"}}>
											Insurance
										</p>
									</Checkbox>

									{/*Title*/}
									<div className="row">
										<p>
											<strong>
												Title
											</strong>
											<span>
												{data.title}
											</span>
										</p>
									</div>

									{/*Path*/}
									<div className="row">
										<p>
											<strong>
												Path
											</strong>
											<span>
												{data.path}
											</span>
										</p>
									</div>

									{/*City*/}
									<div className="row">
										<p>
											<strong>
												City
											</strong>
											<span>
												{data.city}
											</span>
										</p>
									</div>

									{/*Description*/}
									<div className="row">
										<p>
											<strong>
												Description
											</strong>
											<span>
												{data.description}
											</span>
										</p>
									</div>

									{/*Name*/}
									<div className="row">
										<p>
											<strong>
												Name
											</strong>
											<span>
												{data.name}
											</span>
										</p>
									</div>

									{/*Category*/}

									<div className="row">
										<p>
											<strong>
												Category
											</strong>

											<Select
												value={selectedCategory}
												defaultValue={selectedCategory}
												style={{ width: 320 }}
												onChange={handleCategorySelect}
												options={categoriesData}
											/>

											{/*<span>
												{data.category}
											</span>*/}
										</p>
									</div>

									{/*Sub Category*/}
									<div className="row">
										<p>
											<strong>
												Sub category
											</strong>

											<Select
												value={selectedSubCategory}
												defaultValue={selectedSubCategory}
												style={{ width: 320 }}
												onChange={handleSubCategorySelect}
												options={subCategoriesData}
											/>

											{/*<span>
												{data.subCategory}
											</span>*/}
										</p>
									</div>

									{/*Address*/}
									<div className="row">
										<p>
											<strong>
												Address
											</strong>
											<span>
												{data.address}
											</span>
										</p>
									</div>

									{/*Updated At*/}
									<div className="row">
										<p>
											<strong>
												Updated at
											</strong>
											<span>
												{moment(data.updatedAt).format("DD.MM.YYYY HH:mm")}
											</span>
										</p>
									</div>

									{/*Purchase Year*/}
									<div className="row">
										<p>
											<strong>
												Purchase year
											</strong>
											<span>
												{data.purchaseYear}
											</span>
										</p>
									</div>

									{/*Item Value*/}
									<div className="row">
										<p>
											<strong>
												Item value
											</strong>
											<span>
												{data.itemValue}
											</span>
										</p>
									</div>

									{/*Pricing*/}
									<p>
										<strong>
											Day
										</strong>
										<span>
											{data.pricing.daily["1"]}
										</span>
									</p>

									<p>
										<strong>
											2-6 days
										</strong>
										<span>
											{data.pricing.daily["2-6"]}
										</span>
									</p>

									<p>
										<strong>
											7-30 days
										</strong>
										<span>
											{data.pricing.daily["7-30"]}
										</span>
									</p>

									<p>
										<strong>
											30+ days
										</strong>
										<span>
											{data.pricing.daily["30+"]}
										</span>
									</p>

								</div>

							</div>

							<h2>Posts uploaded by user:</h2>
							<Space size={[8, 16]} wrap>
								{userPosts?.length > 0 && userPosts.map((post: any) => (
									<Card
										hoverable
										style={{ width: 240 }}
										cover={<img alt="example" src={post.imagesUrls[0]} />}
										key={post._id}
									>
										<p>
											{post.title}
										</p>
										<p>
											{post.description}
										</p>
										<p>
											{post.city}
										</p>
										<p>
											{post.path}
										</p>
										<p>
											{moment(post.createdAt).format("DD.MM.YYYY HH:mm")}
										</p>
									</Card>
								))}
							</Space>

						</>
					}

				</>
			}

		</DashboardContentBox>
	)
}
