import {Button, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "./CategoryKeywordsTab.css";
import {
	IGetAllCategoriesAndSubCategoriesData,
	IGetAllCategoriesAndSubCategoriesResponse
} from "../../../../../api/services/categoryService/category.interface";
import CategoriesService from "../../../../../api/services/categoryService/category.service";

export default function CategoryKeywordsTab() {
	const { t } = useTranslation();

	const [data, setData] = useState<IGetAllCategoriesAndSubCategoriesData[]>([]);
	const [modalData, setModalData] = useState<IGetAllCategoriesAndSubCategoriesData | undefined>(undefined);
	const [keyInputValues, setKeyInputValues] = useState("");

	const fetchData = async () => {
		await new CategoriesService().getAllCategoriesAndSubCategories()
			.then((data: IGetAllCategoriesAndSubCategoriesResponse) => {
				setData(data.data);
			});
	}

	useEffect(() => {
		fetchData();
	}, [])

	const saveKeywords = async (e: any) => {
		e.preventDefault();
		await new CategoriesService().updateCategoriesKeywords({
			type: modalData?.imageUrl ? "category" : "subCategory",
			id: modalData?._id || "",
			keywords: keyInputValues.split(", ")
		});
		fetchData();
		setModalData(undefined);
		setKeyInputValues("");
	}

	return(
		<>
			<h1>
				Category Keywords
			</h1>

			<div className="categories_list">

				{data.map((item ) => (
					<div
						key={item._id}
						className="categories_list_item"
						onClick={() => {
							setModalData(item)
							setKeyInputValues(item.keys.join(", "))
						}}
					>
						<p>
							{`Type: ${item.imageUrl ? "Category" : "Sub Category"}`}
						</p>
						<p>
							{`Name: ${item.name.de}`}
						</p>
						<p>
							Keywords:
						</p>

						{item.keys?.length > 0
							&&
							<div
								className="keywords_list"
							>
								{item.keys.map((key: string) => (
									<p
										key={key}
									>
										{`${key} |`}
									</p>
								))}
							</div>
						}

					</div>
				))}

			</div>

			<Modal
				visible={modalData && Object.keys(modalData)?.length > 0}
				onCancel={() => setModalData(undefined)}
				footer={null}
				width={800}
				closable={true}
				maskClosable={true}
			>

				<h3>
					{`${modalData?.name.de} | ${modalData?.name.en}`}
				</h3>

				<form
					style={{
						display: "flex",
						flexDirection: "column",
						maxWidth: "400px"
					}}
					onSubmit={saveKeywords}
				>

					<textarea
						value={keyInputValues}
						onChange={(e: any) => setKeyInputValues(e.target.value)}
					/>

					<Button
						type={"primary"}
						htmlType={"submit"}
						style={{marginTop: "14px"}}
					>
						Submit
					</Button>

				</form>

			</Modal>
		</>
	);
}
