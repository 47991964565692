import React, {useEffect, useState} from "react";
import "./styles.css";
import {useTranslation} from "react-i18next";
import DashboardContentBox from "../../../components/DashboardContentBox/DashboardContentBox.component";
import {notification, Table} from "antd";
import PostsService from "../../../api/services/PostsService/posts.service";
import wordCutter from "../../../utils/wordCutter/wordCutter";
import moment from "moment";
import {useUrlSearchParams} from "../../../hooks/useUrlSearchParams";
import {getPostsForApprovalType} from "../../../api/services/PostsService/posts.service.interface";
import {useNavigate} from "react-router-dom";

export default function PostsPage() {
	const { t } = useTranslation();
	const { urlSearch, controlUrlSearch } = useUrlSearchParams();
	const navigate = useNavigate();

	const pageLimit = 100
	const [dataQuery, setDataQuery] = useState({
		page: 1,
		search: ""
	});
	const [totalData, setTotalData] = useState(0);
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		if (urlSearch && Object.keys(urlSearch).length > 0) {
			setDataQuery({
				page: Number(urlSearch.page) || 1,
				search: urlSearch.search
			})
		}
	}, [urlSearch])

	useEffect(() => {
		getData({
			limit: pageLimit,
			skip: (Number(dataQuery.page) - 1) * pageLimit,
			search: dataQuery.search
		})
	}, [dataQuery])

	const getData = async (query: getPostsForApprovalType) => {
		const data = await new PostsService().getPostsForApproval({
			limit: query.limit,
			skip: query.skip,
			search: query.search
		})
		if (data && data.status) {
			setTotalData(data.data.total);
			if (data.data?.data?.length > 0) {
				console.log(data.data?.data[0])
				const formattedData = data.data.data.map((dataRow: any) => {
					return {
						key: dataRow._id,
						title: wordCutter(dataRow.title, 30),
						userName: `${dataRow.owner[0].firstName} ${dataRow.owner[0].lastName}`,
						date: moment(dataRow.updatedAt).format("DD.MM.YYYY HH:mm"),
						city: dataRow.city,
					}
				})
				setTableData(formattedData);
			}
		} else {
			// On error
			notification.error({
				message: "Something went wrong"
			})
		}
	}

	const onPagination = (page: any) => {
		controlUrlSearch.create({...dataQuery, page: page});
		setDataQuery({...dataQuery, page: page});
	}

	const columns = [
		{
			title: "Title",
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: "User name",
			dataIndex: 'userName',
			key: 'userName',
		},
		{
			title: "Date",
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: "City",
			dataIndex: 'city',
			key: 'city',
		}
	];

	return(
		<DashboardContentBox>
			<div className="dashboard_page">

				<h1>Posts for approval</h1>

				<Table
					dataSource={tableData}
					columns={columns}
					pagination={{
						hideOnSinglePage: true,
						current: dataQuery.page,
						defaultCurrent: dataQuery.page,
						defaultPageSize: pageLimit,
						pageSize: pageLimit,
						onChange: onPagination,
						total: totalData
					}}
					onRow={(record: any) => {
						return {
							onClick: () => {
								navigate(record.key);
							}
						};
					}}
				/>

			</div>

		</DashboardContentBox>
	)
}
