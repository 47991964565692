import "./RejectPostModal.css";
import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import FormikInput from "../../Inputs/FormikInput";
import Button from "../../Button/Button";
import {rejectModalOptions} from "./rejectModalOptions";

export type rejectionFormType = {
	reason: string;
	comment: string;
}

interface IProps {
	onRejection: (data: rejectionFormType) => void;
}



export default function RejectPostModal(props: IProps) {
	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: {
			reason: "",
			comment: "",
		},
		validationSchema: Yup.object().shape({
			reason: Yup.string().required("Reason is required field"),
			comment: Yup.string().required("Comment is required field")
		}),
		// handle form submitting
		onSubmit: () => {
			props.onRejection(formik.values);
		},
	});

	return(
		<div className="container">

			<h2>
				Rejection Process
			</h2>


			<div className="reject-modal-grid">
				<form
					onSubmit={formik.handleSubmit}
				>

					<div style={{height: "20px"}} />

					<FormikInput
						htmlFor={"reason"}
						name={"reason"}
						label={"Reason"}
						placeholder={"Reason"}
						value={formik.values.reason}
						handleChange={formik.handleChange}
					/>

					<div style={{height: "20px"}} />

					<FormikInput
						htmlFor={"comment"}
						name={"comment"}
						type={"textarea"}
						rows={6}
						label={"Comment"}
						placeholder={"Comment"}
						value={formik.values.comment}
						handleChange={formik.handleChange}
					/>

					<div style={{height: "20px"}} />

					<Button
						text={"Reject"}
						styleType={"primary"}
						submit={true}
					/>

				</form>

				<div className="reject-modal-options">
					{Object.keys(rejectModalOptions).map((key: string) => (
						<Button
							text={rejectModalOptions[key].key}
							styleType={"outlined"}
							style={{marginBottom: "10px"}}
							onClick={() => {
								formik.setFieldValue("reason", rejectModalOptions[key].title);
								formik.setFieldValue("comment", rejectModalOptions[key].comment);
							}}
							key={key}
						/>
					))}

				</div>

			</div>

		</div>
	)
}
